import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Dataset from '@mui/icons-material/Dataset';
import LogoDevIcon from '@mui/icons-material/LogoDev';

import { useAuth } from '../../contexts/AuthContext/AuthContext.jsx';

const drawerWidthOpen = 280; // Sidebar width when open
const drawerWidthClosed = 60; // Sidebar width when closed

function Sidebar({ className }) {
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(true); // State to manage sidebar open/closed

  return (
    <div className={className}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          width: isOpen ? drawerWidthOpen : drawerWidthClosed,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: isOpen ? drawerWidthOpen : drawerWidthClosed,
            boxSizing: 'border-box',
            transition: 'width 0.3s', // Smooth transition for open/close
          },
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          {/* Toggle Button */}
          <IconButton
            onClick={() => setIsOpen(!isOpen)}
            sx={{ alignSelf: isOpen ? 'flex-end' : 'center', margin: 1 }}
          >
            <MenuIcon />
          </IconButton>

          <List>
            <Tooltip title="Home" placement="right" disableHoverListener={isOpen}>
              <ListItem component={Link} to="/">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                {isOpen && <ListItemText primary="Home" />}
              </ListItem>
            </Tooltip>

            <Tooltip title="Tenants" placement="right" disableHoverListener={isOpen}>
              <ListItem component={Link} to="/tenant-processes">
                <ListItemIcon>
                  <Dataset />
                </ListItemIcon>
                {isOpen && <ListItemText primary="Tenants" />}
              </ListItem>
            </Tooltip>

            <Tooltip title="Logs" placement="right" disableHoverListener={isOpen}>
              <ListItem component={Link} to="/logs">
                <ListItemIcon>
                  <LogoDevIcon />
                </ListItemIcon>
                {isOpen && <ListItemText primary="Logs" />}
              </ListItem>
            </Tooltip>
          </List>

          <Box flexGrow={1} />
          <Divider />

          <List>
            <Tooltip title="Settings" placement="right" disableHoverListener={isOpen}>
              <ListItem component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                {isOpen && <ListItemText primary="Settings" />}
              </ListItem>
            </Tooltip>

            <Tooltip title="Sign Out" placement="right" disableHoverListener={isOpen}>
              <ListItem component={Link} onClick={logout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                {isOpen && <ListItemText primary="Sign Out" />}
              </ListItem>
            </Tooltip>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default Sidebar;
