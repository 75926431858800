import React from 'react';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/system';

const StyledSidebar = styled(Box)(({ theme }) => ({
  width: 250,
  height: '100%',
  background: theme.palette.background.default,
  padding: theme.spacing(1),
  boxSizing: 'border-box',
  overflowY: 'auto',
}));

const StyledNode = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
  backgroundColor: theme.palette.background.paper,
}));

function Sidebar() {

  const onDragStart = (event, nodeType) => {
    console.log(`Dragging start: ${nodeType}`); // Log when drag starts
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <StyledSidebar>
      <StyledNode onDragStart={(event) => onDragStart(event, 'stageNode')} draggable>
        Stage
      </StyledNode>
      <StyledNode onDragStart={(event) => onDragStart(event, 'stepNode')} draggable>
        Step
      </StyledNode>
      <StyledNode onDragStart={(event) => onDragStart(event, 'actionEventNode')} draggable>
        Action Event
      </StyledNode>
    </StyledSidebar>
  );
}

export default Sidebar;
