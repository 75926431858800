import React, { useState } from 'react';
import { Grid, ButtonGroup, Button, MenuItem, FormControl, Select, InputLabel, Box } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PieChartIcon from '@mui/icons-material/PieChart';

const FiltersAndViewToggle = () => {
  // States to manage selected values for dropdowns
  const [classFilter, setClassFilter] = useState('');
  const [segmentFilter, setSegmentFilter] = useState('');
  const [bodyTypeFilter, setBodyTypeFilter] = useState('');
  const [fuelTypeFilter, setFuelTypeFilter] = useState('');
  const [brandFilter, setBrandFilter] = useState('');
  const [dateRange, setDateRange] = useState('July 2024');
  const [comparisonPeriod, setComparisonPeriod] = useState('July 2023 - June 2024');
  const [dayFilter, setDayFilter] = useState('All Days');

  // State for view toggle
  const [view, setView] = useState('Lifecycle View');

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="0 8px 0 8px"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
      bgcolor="#fafafa"
    >
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '10px' }}>
        {/* Top Row Filters */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Class Filter */}
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel style={{
                  color: '#00000099',
                }}>Class</InputLabel>
                <Select
                  value={classFilter}
                  onChange={(e) => setClassFilter(e.target.value)}
                  label="Class"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="Class A">Class A</MenuItem>
                  <MenuItem value="Class B">Class B</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>

            {/* Segment Filter */}
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel style={{
                  color: '#00000099',
                }}>Segment</InputLabel>
                <Select
                  value={segmentFilter}
                  onChange={(e) => setSegmentFilter(e.target.value)}
                  label="Segment"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="Segment 1">Segment 1</MenuItem>
                  <MenuItem value="Segment 2">Segment 2</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>

            {/* Body Type Filter */}
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel style={{
                  color: '#00000099',
                }}>Body Type</InputLabel>
                <Select
                  value={bodyTypeFilter}
                  onChange={(e) => setBodyTypeFilter(e.target.value)}
                  label="Body Type"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="SUV">SUV</MenuItem>
                  <MenuItem value="Sedan">Sedan</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>

            {/* Fuel Type Filter */}
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel style={{
                  color: '#00000099',
                }}>Fuel Type</InputLabel>
                <Select
                  value={fuelTypeFilter}
                  onChange={(e) => setFuelTypeFilter(e.target.value)}
                  label="Fuel Type"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="Gasoline">Gasoline</MenuItem>
                  <MenuItem value="Diesel">Diesel</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>

            {/* Brand Filter */}
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel style={{
                  color: '#00000099',
                }}>Brand</InputLabel>
                <Select
                  value={brandFilter}
                  onChange={(e) => setBrandFilter(e.target.value)}
                  label="Brand"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  <MenuItem value="Brand A">Brand A</MenuItem>
                  <MenuItem value="Brand B">Brand B</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Second Row Filters */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Date Range Filter */}
            <Grid item xs={12} sm={2}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel style={{
                  color: '#00000099',
                }}>Date Range</InputLabel>
                <Select
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                  label="Date Range"
                >
                  <MenuItem value="July 2024">July 2024</MenuItem>
                  <MenuItem value="June 2024">June 2024</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>

            {/* Comparison Reference Period Filter */}
            <Grid item xs={12} sm={3.5}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel style={{
                  color: '#00000099',
                }}>Comparison Reference Period</InputLabel>
                <Select
                  value={comparisonPeriod}
                  onChange={(e) => setComparisonPeriod(e.target.value)}
                  label="Comparison Reference Period"
                >
                  <MenuItem value="July 2023 - June 2024">July 2023 - June 2024</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>

            {/* Day Filter */}
            <Grid item xs={12} sm={2.5}>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel style={{
                  color: '#00000099',
                }}>Day Filter</InputLabel>
                <Select
                  value={dayFilter}
                  onChange={(e) => setDayFilter(e.target.value)}
                  label="Day Filter"
                >
                  <MenuItem value="All Days">All Days</MenuItem>
                  <MenuItem value="Business Days Only">Business Days Only</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        {/* View Toggle Button Group */}
        <Grid item xs={3} sm={12} container justifyContent="right">
          <ButtonGroup variant="outlined" size="small">
            <Button
              variant={view === 'Lifecycle View' ? 'contained' : 'outlined'}
              onClick={() => setView('Lifecycle View')}
              color={view === 'Lifecycle View' ? 'success' : 'primary'}
              style={{
                textTransform: 'none',
                backgroundColor: view === 'Lifecycle View' ? '#00965a' : 'white',
                textAlign: 'center',
                fontFeatureSettings: 'liga off clig off',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '160%',
                letterSpacing: '0.15px',
              }}
              startIcon={<FormatListBulletedIcon style={{ width: '18px', marginRight: '4px' }} />}
            >
              Lifecycle View
            </Button>
            <Button
              variant={view === 'Segment View' ? 'contained' : 'outlined'}
              onClick={() => setView('Segment View')}
              color={view === 'Segment View' ? 'success' : 'primary'}
              startIcon={<PieChartIcon style={{ width: '18px' }} />}
              style={{
                textTransform: 'none',
                backgroundColor: view === 'Segment View' ? '#00965a' : 'white',
                textAlign: 'center',
                fontFeatureSettings: 'liga off clig off',
                fontFamily: 'Roboto',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '160%',
                letterSpacing: '0.15px',
              }}
            >
              Segment View
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

    </Box>
  );
};

export default FiltersAndViewToggle;
