import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import RidecellLogoWhite from './../../assets/ridecell_logo_white.png';

function Header({ className }) {
  return (
    // 'linear-gradient(180deg, #A05EB5 1%, #00B5E2 80%)'
    <div className={className}>
      <AppBar position="static" sx={{ background: '#A05EB5' }}>
        <Toolbar>
          <img src={RidecellLogoWhite} alt="Ridecell Logo" style={{ height: '50px' }} />
        </Toolbar>
      </AppBar>
    </div >
  );
}

export default Header;
