// Custom text node component for the centered text
const TextNode = ({ data }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: '18px',
        color: '#000000',
        maxWidth: '150px', // Set a maximum width to enable wrapping
        wordWrap: 'break-word', // Enable word wrapping
        whiteSpace: 'normal', // Allow text to wrap normally
        padding: '5px',
      }}
    >

      {data.fleet_logo && <img src={data.fleet_logo} alt={data.fleet_id} style={{ width: '112px', height: '34px' }} />}
      {!data.fleet_logo && <b>{data.fleet_id}</b>}
      <br />{data.text}
    </div>
  );
};

export default TextNode;