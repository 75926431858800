import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  IconButton,
  Box
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { getLogs } from '../../services/logsAPI';

const LOG_METHODS = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH']; // Example methods

function LogsPage() {
  const { token } = useAuth();
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');
  const [openRows, setOpenRows] = useState({});

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    const logsData = await getLogs({ token }); // Fetch logs from your backend
    const logsArray = [...Object.values(logsData?.salesforce ?? []), ...Object.values(logsData?.ams ?? [])];
    setLogs(logsArray);
    setFilteredLogs(logsArray); // Initialize with all logs
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterLogs(e.target.value, selectedMethod);
  };

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
    filterLogs(searchTerm, e.target.value);
  };

  const filterLogs = (term, method) => {
    let filtered = logs;

    if (term) {
      filtered = filtered.filter(log =>
        log.url.includes(term) || log.method.includes(term)
      );
    }

    if (method) {
      filtered = filtered.filter(log => log.method === method);
    }

    setFilteredLogs(filtered);
  };

  const handleRowClick = (index) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [index]: !prevOpenRows[index]
    }));
  };

  return (
    <Container maxWidth={false}>
      <Typography variant="h4" paragraph>
        Logs
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginRight: '8px' }}
        />
        <TextField
          label="Method"
          variant="outlined"
          fullWidth
          select
          value={selectedMethod}
          onChange={handleMethodChange}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {LOG_METHODS.map((method) => (
            <MenuItem key={method} value={method}>
              {method}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Method</TableCell>
              <TableCell>Path</TableCell>
              <TableCell align="right">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLogs.map((log, index) => (
              <React.Fragment key={index}>
                <TableRow onClick={() => handleRowClick(index)}>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleRowClick(index)}
                    >
                      {openRows[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{log.method}</TableCell>
                  <TableCell>{log.url}</TableCell>
                  <TableCell align="right">{new Date(log.timestamp).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Details
                        </Typography>
                        <pre>{JSON.stringify(log, null, 2)}</pre>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default LogsPage;
