import React, { useCallback, useRef, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  useReactFlow,
  ReactFlowProvider,
} from 'reactflow';
import 'reactflow/dist/style.css';

import ArrowNode from '../../components/Intelligence/IntelligenceArrowNode';
import CustomNode from '../../components/Intelligence/IntelligenceNode';
import TextNode from './../../components/Intelligence/IntelligenceTextNode';
import CircleFlowNode from '../../components/Intelligence/IntelligenceCircleFlowNode';
import CustomEdge from '../../components/Intelligence/IntelligenceCustomEdge';


const NodeIds = {
  FLEET_IN: 'Fleet In',
  CONTRACT_IN: 'Contract In',
  CONTRACT: 'Contract',
  CONTRACT_OUT: 'Contract Out',
  ASSET_ASSESSMENT: 'Asset Assessment',
  FLEET_OUT: 'Fleet Out',
  STOCK_IN: 'Stock In',
  STOCK: 'Stock',
};

const nodeWidth = 300;
const nodeHeight = 176;
const nodeSpacing = 40;

const initialNodes = (fleetData, tab = 'Idle Time') => {
  let retVal = []
  if (tab === 'Idle Time') {
    retVal = [
      {
        id: NodeIds.FLEET_IN,
        position: { x: 0, y: (nodeHeight) + (nodeSpacing) },
        data: {
          title: NodeIds.FLEET_IN,
          avgIdle: (fleetData.seasons[NodeIds.FLEET_IN]?.metrics.idle_days || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.FLEET_IN]?.metrics.throughput || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.FLEET_IN]?.metrics_change_percent.idle_days * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.FLEET_IN]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.FLEET_IN]?.metrics_change_indicator.idle_days.icon,
            color: fleetData.seasons[NodeIds.FLEET_IN]?.metrics_change_indicator.idle_days.color,
          },
          handles: {
            right: { type: "source", enabled: true },
            bottom: { type: "target", enabled: true },
            top: { type: "source", enabled: false },
            left: { type: "source", enabled: false },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.CONTRACT_IN,
        position: { x: (nodeWidth * 1) + nodeSpacing, y: (nodeHeight) + (nodeSpacing) },
        data: {
          title: NodeIds.CONTRACT_IN,
          avgIdle: (fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics.idle_days || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics.throughput || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics_change_percent.idle_days * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.CONTRACT_IN]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics_change_indicator.idle_days.icon,
            color: fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics_change_indicator.idle_days.color,
          },
          handles: {
            right: { type: "source", enabled: false },
            bottom: { type: "target", enabled: true },
            top: { type: "source", enabled: true },
            left: { type: "target", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.CONTRACT,
        position: { x: (nodeWidth * 1.45) + nodeSpacing, y: 0 },
        data: {
          title: NodeIds.CONTRACT,
          avgIdle: (fleetData.seasons[NodeIds.CONTRACT]?.metrics.idle_days || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.CONTRACT]?.metrics.throughput || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.CONTRACT]?.metrics_change_percent.idle_days * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.CONTRACT]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.CONTRACT]?.metrics_change_indicator.idle_days.icon,
            color: fleetData.seasons[NodeIds.CONTRACT]?.metrics_change_indicator.idle_days.color,
          },
          handles: {
            right: { type: "source", enabled: true },
            bottom: { type: "target", enabled: true },
            top: { type: "source", enabled: false },
            left: { type: "source", enabled: false },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.CONTRACT_OUT,
        position: { x: (nodeWidth * 2.45) + (nodeSpacing * 2), y: 0 },
        data: {
          title: NodeIds.CONTRACT_OUT,
          avgIdle: (fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics.idle_days || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics.throughput || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics_change_percent.idle_days * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.CONTRACT_OUT]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics_change_indicator.idle_days.icon,
            color: fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics_change_indicator.idle_days.color,
          },
          handles: {
            right: { type: "source", enabled: false },
            bottom: { type: "source", enabled: true },
            top: { type: "source", enabled: false },
            left: { type: "target", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.ASSET_ASSESSMENT,
        position: { x: (nodeWidth * 2.90) + (nodeSpacing * 2), y: nodeHeight + (nodeSpacing) },
        data: {
          title: NodeIds.ASSET_ASSESSMENT,
          avgIdle: (fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics.idle_days || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics.throughput || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics_change_percent.idle_days * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics_change_indicator.idle_days.icon,
            color: fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics_change_indicator.idle_days.color,
          },
          handles: {
            right: { type: "source", enabled: true },
            bottom: { type: "source", enabled: true },
            top: { type: "target", enabled: true },
            left: { type: "none", enabled: false },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.FLEET_OUT,
        position: { x: (nodeWidth * 4) + (nodeSpacing * 2.5), y: nodeHeight + (nodeSpacing) },
        data: {
          title: NodeIds.FLEET_OUT,
          avgIdle: (fleetData.seasons[NodeIds.FLEET_OUT]?.metrics.idle_days || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.FLEET_OUT]?.metrics.throughput || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.FLEET_OUT]?.metrics_change_percent.idle_days * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.FLEET_OUT]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.FLEET_OUT]?.metrics_change_indicator.idle_days.icon,
            color: fleetData.seasons[NodeIds.FLEET_OUT]?.metrics_change_indicator.idle_days.color,
          },
          handles: {
            right: { type: "source", enabled: false },
            bottom: { type: "source", enabled: true },
            top: { type: "source", enabled: false },
            left: { type: "target", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.STOCK_IN,
        position: { x: (nodeWidth * 2.45) + (nodeSpacing * 2), y: (nodeHeight * 2 + nodeSpacing) + (nodeSpacing) },
        data: {
          title: NodeIds.STOCK_IN,
          avgIdle: (fleetData.seasons[NodeIds.STOCK_IN]?.metrics.idle_days || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.STOCK_IN]?.metrics.throughput || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.STOCK_IN]?.metrics_change_percent.idle_days * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.STOCK_IN]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.STOCK_IN]?.metrics_change_indicator.idle_days.icon,
            color: fleetData.seasons[NodeIds.STOCK_IN]?.metrics_change_indicator.idle_days.color,
          },
          handles: {
            right: { type: "none", enabled: false },
            bottom: { type: "source", enabled: false },
            top: { type: "target", enabled: true },
            left: { type: "source", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.STOCK,
        position: { x: (nodeWidth * 1.45) + (nodeSpacing * 1), y: (nodeHeight * 2 + nodeSpacing) + (nodeSpacing) },
        data: {
          title: NodeIds.STOCK,
          avgIdle: (fleetData.seasons[NodeIds.STOCK]?.metrics.idle_days || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.STOCK]?.metrics.throughput || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.STOCK]?.metrics_change_percent.idle_days * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.STOCK]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.STOCK]?.metrics_change_indicator.idle_days.icon,
            color: fleetData.seasons[NodeIds.STOCK]?.metrics_change_indicator.idle_days.color,
          },
          handles: {
            right: { type: "target", enabled: true },
            bottom: { type: "none", enabled: false },
            top: { type: "source", enabled: true },
            left: { type: "target", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: 'center-text-1',
        position: { x: (nodeWidth * 2.12) + (nodeSpacing * 2), y: nodeHeight + (nodeSpacing * 1.5) },
        data: {
          text: `Utilization Rate: ${(fleetData.fleet_metrics?.utilization_rate * 100 || 0).toFixed(1)}%`,
          fleet_id: fleetData.query_parameters.fleet_id,
          fleet_logo: fleetData.query_parameters.fleet_logo,
        },
        type: 'textNode',
      },
      {
        id: 'arrow-node-fleet-in',
        position: {
          x: (nodeWidth / 2) - 16, y: (nodeHeight * 2.15)
        },
        data: { rotation: 0 },
        type: 'arrowNode',
      },
      {
        id: 'arrow-node-fleet-out',
        position: {
          x: (nodeWidth * 4) + (nodeSpacing * 2.5) + ((nodeWidth / 2) - 16), y: (nodeHeight * 2.15)
        },
        data: { rotation: 180 },
        type: 'arrowNode',
      },
      {
        id: 'circleNode',
        type: 'flowNode', // use the custom node type
        position: { x: (nodeWidth) + 210, y: 16 },
        data: {}, // Add any data you want here
        zIndex: -1,
        draggable: false,
        style: { pointerEvents: 'none' },
      },
    ];
  } else if (tab === 'Utilization Rate') {
    retVal = [
      {
        id: NodeIds.FLEET_IN,
        position: { x: 0, y: (nodeHeight) + (nodeSpacing) },
        data: {
          title: NodeIds.FLEET_IN,
          avgIdle: (fleetData.seasons[NodeIds.FLEET_IN]?.metrics.operational_rate || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.FLEET_IN]?.metrics.average_vehicles || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.FLEET_IN]?.metrics_change_percent.operational_rate * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.FLEET_IN]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.FLEET_IN]?.metrics_change_indicator.operational_rate.icon,
            color: fleetData.seasons[NodeIds.FLEET_IN]?.metrics_change_indicator.operational_rate.color,
          },
          handles: {
            right: { type: "source", enabled: true },
            bottom: { type: "target", enabled: true },
            top: { type: "source", enabled: false },
            left: { type: "source", enabled: false },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.CONTRACT_IN,
        position: { x: (nodeWidth * 1) + nodeSpacing, y: (nodeHeight) + (nodeSpacing) },
        data: {
          title: NodeIds.CONTRACT_IN,
          avgIdle: (fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics.operational_rate || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics.average_vehicles || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics_change_percent.operational_rate * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.CONTRACT_IN]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics_change_indicator.operational_rate.icon,
            color: fleetData.seasons[NodeIds.CONTRACT_IN]?.metrics_change_indicator.operational_rate.color,
          },
          handles: {
            right: { type: "source", enabled: false },
            bottom: { type: "target", enabled: true },
            top: { type: "source", enabled: true },
            left: { type: "target", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.CONTRACT,
        position: { x: (nodeWidth * 1.45) + nodeSpacing, y: 0 },
        data: {
          title: NodeIds.CONTRACT,
          avgIdle: (fleetData.seasons[NodeIds.CONTRACT]?.metrics.operational_rate || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.CONTRACT]?.metrics.average_vehicles || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.CONTRACT]?.metrics_change_percent.operational_rate * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.CONTRACT]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.CONTRACT]?.metrics_change_indicator.operational_rate.icon,
            color: fleetData.seasons[NodeIds.CONTRACT]?.metrics_change_indicator.operational_rate.color,
          },
          handles: {
            right: { type: "source", enabled: true },
            bottom: { type: "target", enabled: true },
            top: { type: "source", enabled: false },
            left: { type: "source", enabled: false },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.CONTRACT_OUT,
        position: { x: (nodeWidth * 2.45) + (nodeSpacing * 2), y: 0 },
        data: {
          title: NodeIds.CONTRACT_OUT,
          avgIdle: (fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics.operational_rate || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics.average_vehicles || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics_change_percent.operational_rate * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.CONTRACT_OUT]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics_change_indicator.operational_rate.icon,
            color: fleetData.seasons[NodeIds.CONTRACT_OUT]?.metrics_change_indicator.operational_rate.color,
          },
          handles: {
            right: { type: "source", enabled: false },
            bottom: { type: "source", enabled: true },
            top: { type: "source", enabled: false },
            left: { type: "target", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.ASSET_ASSESSMENT,
        position: { x: (nodeWidth * 2.90) + (nodeSpacing * 2), y: nodeHeight + (nodeSpacing) },
        data: {
          title: NodeIds.ASSET_ASSESSMENT,
          avgIdle: (fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics.operational_rate || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics.average_vehicles || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics_change_percent.operational_rate * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics_change_indicator.operational_rate.icon,
            color: fleetData.seasons[NodeIds.ASSET_ASSESSMENT]?.metrics_change_indicator.operational_rate.color,
          },
          handles: {
            right: { type: "source", enabled: true },
            bottom: { type: "source", enabled: true },
            top: { type: "target", enabled: true },
            left: { type: "none", enabled: false },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.FLEET_OUT,
        position: { x: (nodeWidth * 4) + (nodeSpacing * 2.5), y: nodeHeight + (nodeSpacing) },
        data: {
          title: NodeIds.FLEET_OUT,
          avgIdle: (fleetData.seasons[NodeIds.FLEET_OUT]?.metrics.operational_rate || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.FLEET_OUT]?.metrics.average_vehicles || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.FLEET_OUT]?.metrics_change_percent.operational_rate * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.FLEET_OUT]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.FLEET_OUT]?.metrics_change_indicator.operational_rate.icon,
            color: fleetData.seasons[NodeIds.FLEET_OUT]?.metrics_change_indicator.operational_rate.color,
          },
          handles: {
            right: { type: "source", enabled: false },
            bottom: { type: "source", enabled: true },
            top: { type: "source", enabled: false },
            left: { type: "target", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.STOCK_IN,
        position: { x: (nodeWidth * 2.45) + (nodeSpacing * 2), y: (nodeHeight * 2 + nodeSpacing) + (nodeSpacing) },
        data: {
          title: NodeIds.STOCK_IN,
          avgIdle: (fleetData.seasons[NodeIds.STOCK_IN]?.metrics.operational_rate || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.STOCK_IN]?.metrics.average_vehicles || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.STOCK_IN]?.metrics_change_percent.operational_rate * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.STOCK_IN]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.STOCK_IN]?.metrics_change_indicator.operational_rate.icon,
            color: fleetData.seasons[NodeIds.STOCK_IN]?.metrics_change_indicator.operational_rate.color,
          },
          handles: {
            right: { type: "none", enabled: false },
            bottom: { type: "source", enabled: false },
            top: { type: "target", enabled: true },
            left: { type: "source", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: NodeIds.STOCK,
        position: { x: (nodeWidth * 1.45) + (nodeSpacing * 1), y: (nodeHeight * 2 + nodeSpacing) + (nodeSpacing) },
        data: {
          title: NodeIds.STOCK,
          avgIdle: (fleetData.seasons[NodeIds.STOCK]?.metrics.operational_rate || 0).toFixed(1),
          throughput: (fleetData.seasons[NodeIds.STOCK]?.metrics.average_vehicles || 0).toFixed(0),
          trend: (Math.floor(fleetData.seasons[NodeIds.STOCK]?.metrics_change_percent.operational_rate * 100 || 0)).toFixed(0),
          stages: fleetData.seasons[NodeIds.STOCK]?.stages || [],
          indicator: {
            direction: fleetData.seasons[NodeIds.STOCK]?.metrics_change_indicator.operational_rate.icon,
            color: fleetData.seasons[NodeIds.STOCK]?.metrics_change_indicator.operational_rate.color,
          },
          handles: {
            right: { type: "target", enabled: true },
            bottom: { type: "none", enabled: false },
            top: { type: "source", enabled: true },
            left: { type: "target", enabled: true },
          },
          intelligenceDataView: tab,
        },
        type: 'customNode',
      },
      {
        id: 'center-text-1',
        position: { x: (nodeWidth * 2.12) + (nodeSpacing * 2), y: nodeHeight + (nodeSpacing * 1.5) },
        data: {
          text: `Utilization Rate: ${(fleetData.fleet_metrics?.utilization_rate * 100 || 0).toFixed(1)}%`,
          fleet_id: fleetData.query_parameters.fleet_id,
          fleet_logo: fleetData.query_parameters.fleet_logo,
        },
        type: 'textNode',
      },
      {
        id: 'arrow-node-fleet-in',
        position: {
          x: (nodeWidth / 2) - 16, y: (nodeHeight * 2.15)
        },
        data: { rotation: 0 },
        type: 'arrowNode',
      },
      {
        id: 'arrow-node-fleet-out',
        position: {
          x: (nodeWidth * 4) + (nodeSpacing * 2.5) + ((nodeWidth / 2) - 16), y: (nodeHeight * 2.15)
        },
        data: { rotation: 180 },
        type: 'arrowNode',
      },
      {
        id: 'circleNode',
        type: 'flowNode', // use the custom node type
        position: { x: (nodeWidth) + 210, y: 16 },
        data: {}, // Add any data you want here
        zIndex: -1,
        draggable: false,
        style: { pointerEvents: 'none' },
      },
    ];
  }
  return retVal;
}



const initialEdges = [
  {
    id: `edge-${NodeIds.FLEET_IN}-${NodeIds.CONTRACT_IN}`,
    source: NodeIds.FLEET_IN,
    sourceHandle: 'right',
    target: NodeIds.CONTRACT_IN,
    targetHandle: 'left',
    type: 'customEdge',
    animated: false,
    style: { stroke: 'black' },
    data: { angle: 90, isAtEnd: false, isStraightLine: true, xOffset: -4, yOffset: 0 },
  },
  // {
  //   id: `edge-${NodeIds.FLEET_IN}-${NodeIds.STOCK}`,
  //   source: NodeIds.FLEET_IN,
  //   sourceHandle: 'right',
  //   target: NodeIds.STOCK,
  //   type: 'customEdge',
  //   targetHandle: 'left',
  //   animated: false,
  //   style: { stroke: 'black' },
  //   data: { angle: 90, isAtEnd: true, isStraightLine: false, xOffset: -4, yOffset: 0 },
  // },
  // {

  //   id: `edge-${NodeIds.CONTRACT_IN}-${NodeIds.CONTRACT}`,
  //   source: NodeIds.CONTRACT_IN,
  //   sourceHandle: 'top',
  //   target: NodeIds.CONTRACT,
  //   targetHandle: 'bottom',
  //   animated: false,
  //   type: 'customEdge',
  //   style: { stroke: 'black' },
  //   data: { angle: 65, isAtEnd: false, isStraightLine: true, xOffset: -4, yOffset: 0 },
  // },
  // {
  //   id: `edge-${NodeIds.CONTRACT}-${NodeIds.CONTRACT_OUT}`,
  //   source: NodeIds.CONTRACT,
  //   sourceHandle: 'right',
  //   target: NodeIds.CONTRACT_OUT,
  //   targetHandle: 'left',
  //   animated: false,
  //   type: 'customEdge',
  //   style: { stroke: 'black' },
  //   data: { angle: 90, isAtEnd: false, isStraightLine: true, xOffset: -4, yOffset: 0 },
  // },
  // {
  //   id: `edge-${NodeIds.CONTRACT_OUT}-${NodeIds.ASSET_ASSESSMENT}`,
  //   source: NodeIds.CONTRACT_OUT,
  //   sourceHandle: 'bottom',
  //   target: NodeIds.ASSET_ASSESSMENT,
  //   targetHandle: 'top',
  //   type: 'customEdge',
  //   animated: false,
  //   style: { stroke: 'black' },
  //   data: { angle: 105, isAtEnd: false, isStraightLine: true, xOffset: -4, yOffset: 0 },
  // },
  {
    id: `edge-${NodeIds.ASSET_ASSESSMENT}-${NodeIds.FLEET_OUT}`,
    source: NodeIds.ASSET_ASSESSMENT,
    sourceHandle: 'right',
    target: NodeIds.FLEET_OUT,
    targetHandle: 'left',
    type: 'customEdge',
    animated: false,
    style: { stroke: 'black' },
    data: { angle: 90, isAtEnd: false, isStraightLine: true, xOffset: -4, yOffset: 0 },
  },
  // {
  //   id: `edge-${NodeIds.ASSET_ASSESSMENT}-${NodeIds.STOCK_IN}`,
  //   source: NodeIds.ASSET_ASSESSMENT,
  //   sourceHandle: 'bottom',
  //   target: NodeIds.STOCK_IN,
  //   targetHandle: 'top',
  //   type: 'customEdge',
  //   animated: false,
  //   style: { stroke: 'black' },
  //   data: { angle: -105, isAtEnd: false, isStraightLine: true, xOffset: -4, yOffset: 0 },
  // },
  // {
  //   id: `edge-${NodeIds.STOCK_IN}-${NodeIds.STOCK}`,
  //   source: NodeIds.STOCK_IN,
  //   sourceHandle: 'left',
  //   target: NodeIds.STOCK,
  //   targetHandle: 'right',
  //   type: 'customEdge',
  //   animated: false,
  //   style: { stroke: 'black' },
  //   data: { angle: -90, isAtEnd: false, isStraightLine: true, xOffset: -4, yOffset: 0 },
  // },
  // {
  //   id: `edge-${NodeIds.STOCK}-${NodeIds.CONTRACT_IN}`,
  //   source: NodeIds.STOCK,
  //   sourceHandle: 'top',
  //   target: NodeIds.CONTRACT_IN,
  //   targetHandle: 'bottom',
  //   type: 'customEdge',
  //   animated: false,
  //   style: { stroke: 'black' },
  //   data: { angle: -60, isAtEnd: false, isStraightLine: true, xOffset: -4, yOffset: 0 },
  // },
];


const nodeTypes = {
  customNode: CustomNode,
  textNode: TextNode,
  arrowNode: ArrowNode,
  flowNode: CircleFlowNode,
};

const edgeTypes = {
  customEdge: CustomEdge,
};

const IntelligenceVisualizer = ({ fleetData, fleetId, intelligenceDataView }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const { fitView } = useReactFlow();

  useEffect(() => {
    const handleResize = () => {
      fitView({ padding: 0.1 });
    };

    window.addEventListener('resize', handleResize);

    // Call fitView initially to set the view correctly
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [fitView]);

  useEffect(() => {
    setNodes(initialNodes(fleetData, intelligenceDataView));
  }, [intelligenceDataView, fleetData, fleetId]);

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        defaultEdgeOptions={{ deletable: false, focusable: false }}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
        fitViewOptions={{ padding: 0.1 }}
        panOnDrag={true}
        panOnScroll={true}
        nodesDraggable={false}
        zoomOnScroll={false}
        panOnScrollMode='noPan'
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        minZoom={1}
        maxZoom={1}
        proOptions={{ hideAttribution: true }}
        style={{
          backgroundColor: '#fafafa',
        }}
      >
      </ReactFlow>
    </>
  );
};

const WithFlowProvider = ({ fleetData, intelligenceDataView }) => (
  <ReactFlowProvider>
    <IntelligenceVisualizer fleetData={fleetData} intelligenceDataView={intelligenceDataView} />
  </ReactFlowProvider>
);

export default WithFlowProvider;
