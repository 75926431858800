import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';

const cardData = [
  {
    title: 'Lifecycle Workflow Manager',
    description: 'Manage the lifecycle workflows for this tenant.',
    appName: 'lifecycle-workflow-manager'
  },
  {
    title: 'Vehicle Order Form',
    description: 'Order a new vehicle to be added to the fleet.',
    appName: 'vehicle-order-form'
  },
  {
    title: 'Platform One',
    description: 'Platform One Embed Tester',
    appName: 'platform-one'
  },
  {
    title: 'Intelligence',
    description: 'Intelligence dashboard for this tenant.',
    appName: 'intelligence'
  },
  {
    title: 'Transport Request',
    description: 'Transport request form.',
    appName: 'transport-request'
  },
  {
    title: 'Vehicle On Boarding Checklist',
    description: 'A simple checklist form, for performing actions related to the onboarding of a vehicle.',
    appName: 'vehicle-onboarding'
  }
  // Add more objects for other apps here in the future
];

function Dashboard() {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleCardClick = (appName) => {
    navigate(`/tenant-processes/${id}/${appName}`);
  };

  return (
    <Container>
      <Typography variant="h4" paragraph>
        Dashboard for Tenant: {id}
      </Typography>
      <Grid container spacing={3}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {card.title}
                </Typography>
                <Typography color="textSecondary">
                  {card.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => handleCardClick(card.appName)}>
                  Open App
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Dashboard;
