import React from 'react';
import { Handle } from 'reactflow';

// Custom arrow node component
const ArrowNode = ({ data }) => {
  const { rotation = 0 } = data;

  return (
    <div
      style={{
        transform: `rotate(${rotation}deg)`,
        width: '37px',
        height: '40px',
        position: 'relative',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="40"
        viewBox="0 0 37 40"
        fill="none"
        style={{ display: 'block', margin: 'auto' }}
      >
        <path
          d="M21.4632 39.8349L21.4938 11.7722L32.4134 22.776L36.7021 18.4421L18.4521 0L0.202148 18.4421L4.4909 22.776L15.4105 11.7722L15.3798 39.8349H21.4632Z"
          fill="#00A48F" // Arrow color
        />
      </svg>
      {/* Top handle */}
      <Handle
        id="top"
        type="source"
        position="top"
        style={{
          top: '-10px',
          background: 'transparent',
          border: 'none',
        }}
      />
      {/* Bottom handle */}
      <Handle
        id="bottom"
        type="target"
        position="bottom"
        style={{
          bottom: '-10px',
          background: 'transparent',
          border: 'none',
        }}
      />
    </div>
  );
};

export default ArrowNode;
