import React, { createContext, useContext, useEffect, useState } from 'react';
import Axios from 'axios';

import useIsEmbedded from '../../hooks/useIsEmbedded'

// Create the Auth context
const AuthContext = createContext();

// Custom hook to use the Auth context
export const useAuth = (options = {}) => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {

  const isEmbedded = useIsEmbedded();

  const tokenDetailsStorageKey = '_t';
  const tokenDetailsEmbeddedStorageKey = '_t_emb';
  const tenantIdStorageKey = '_tId';

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem(isEmbedded ? tokenDetailsStorageKey + '_emb' : tokenDetailsStorageKey));


  const checkToken = async (token) => {
    try {
      const response = await Axios.get('/api/v1/health/validate-token', {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      return response.status === 200;
    } catch (error) {
      console.error('Token validation failed', error);
      return false;
    }
  }

  const login = async (token) => {
    const isValid = await checkToken(token);
    if (isValid) {
      setIsAuthenticated(true);
      setToken(token);
      sessionStorage.setItem(isEmbedded ? tokenDetailsEmbeddedStorageKey : tokenDetailsStorageKey, token);
    } else {
      setIsAuthenticated(false);
      setToken(null);
      sessionStorage.removeItem(tokenDetailsStorageKey);
    }
  };

  const logout = () => {
    sessionStorage.removeItem(tokenDetailsStorageKey);
    setToken(null);
    setIsAuthenticated(false);
  };

  const handleMessage = (event) => {

    if (!isEmbedded) {
      return;
    }
    if (!isEmbedded && event.data.authToken === null || event.data.tenantId === null) {
      return;
    }
    // If the origin isn't from a ridecell domain, ignore the message
    const ridecellRegex = /^https?:\/\/([a-zA-Z0-9-]+\.)?ridecell\.(com|io)$/;
    if (event.data.type !== 'SUITOOL_INIT' && !ridecellRegex.test(event.origin)) {
      return;
    }
    sessionStorage.setItem(tokenDetailsEmbeddedStorageKey, event.data.authToken);
    sessionStorage.setItem(tenantIdStorageKey, event.data.tenantId);
    login(event.data.authToken);
  }


  useEffect(() => {

    async function processToken(token) {
      if (token) {
        const isValid = await checkToken(token);
        if (isValid) {
          setIsAuthenticated(true);
          setToken(token);
          sessionStorage.setItem(isEmbedded ? tokenDetailsEmbeddedStorageKey : tokenDetailsStorageKey, token);
        } else {
          setIsAuthenticated(false);
          sessionStorage.removeItem(tokenDetailsStorageKey);
        }
      } else {
        setIsAuthenticated(false);
        setToken(null);
        sessionStorage.removeItem(tokenDetailsStorageKey);
      }
    }

    const currentToken = token;

    if (currentToken) {
      processToken(currentToken);
    }

  }, [token, !isEmbedded]);

  useEffect(() => {
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [isEmbedded])


  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
