import React from 'react';
import { List, ListItem, ListItemText, Button } from '@mui/material';

function DataSourceList({ dataSources, onEdit }) {
  return (
    <List>
      {dataSources.map((source) => (
        <ListItem
          key={source.id}
          sx={{
            backgroundColor: source.isSelected ? '#a5d6a7' : 'inherit', // Light green when selected
            '&:hover': {
              backgroundColor: '#c8e6c9', // Even lighter green on hover
            },
            mb: 1,
          }}
        >
          <ListItemText primary={source.name} secondary={source.api_url} />
          <Button variant="outlined" onClick={() => onEdit(source)}>
            Edit
          </Button>
        </ListItem>
      ))}
    </List>
  );
}

export default DataSourceList;
