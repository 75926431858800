import React, { useState } from 'react';
import { Container, Button, Typography, Divider, TextField, Grid, MenuItem } from '@mui/material';

function TransportationRequestForm() {
  const [transportDetails, setTransportDetails] = useState({
    vehicleType: '',
    transportMethod: '',
    priority: 'standard',
  });
  const [pickupDetails, setPickupDetails] = useState({
    address: '',
    contactName: '',
    contactPhone: '',
    pickupDate: '',
  });
  const [deliveryDetails, setDeliveryDetails] = useState({
    address: '',
    contactName: '',
    contactPhone: '',
    deliveryDate: '',
  });
  const [additionalNotes, setAdditionalNotes] = useState('');

  const handleChange = (setFunc) => (event) => {
    const { name, value } = event.target;
    setFunc((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    const payload = {
      transportDetails,
      pickupDetails,
      deliveryDetails,
      additionalNotes,
      status: 'REQUESTED',
      requestedAt: new Date().toISOString(),
    };

    console.log('Transportation Request Payload:', payload);
    // Add API call here to send payload
  };

  return (
    <Container>
      <Typography variant="h4" paragraph>
        Transportation Request Form
      </Typography>

      <Divider style={{ marginBottom: '24px' }} />

      <Typography variant="h5" gutterBottom>
        Transport Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Vehicle Type"
            name="vehicleType"
            fullWidth
            value={transportDetails.vehicleType}
            onChange={handleChange(setTransportDetails)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Transport Method"
            name="transportMethod"
            fullWidth
            value={transportDetails.transportMethod}
            onChange={handleChange(setTransportDetails)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Priority"
            name="priority"
            fullWidth
            value={transportDetails.priority}
            onChange={handleChange(setTransportDetails)}
          >
            <MenuItem value="standard">Standard</MenuItem>
            <MenuItem value="expedited">Expedited</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Divider style={{ margin: '24px 0' }} />

      <Typography variant="h5" gutterBottom>
        Pickup Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Pickup Address"
            name="address"
            fullWidth
            value={pickupDetails.address}
            onChange={handleChange(setPickupDetails)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contact Name"
            name="contactName"
            fullWidth
            value={pickupDetails.contactName}
            onChange={handleChange(setPickupDetails)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contact Phone"
            name="contactPhone"
            fullWidth
            value={pickupDetails.contactPhone}
            onChange={handleChange(setPickupDetails)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Pickup Date"
            name="pickupDate"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={pickupDetails.pickupDate}
            onChange={handleChange(setPickupDetails)}
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: '24px 0' }} />

      <Typography variant="h5" gutterBottom>
        Delivery Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Delivery Address"
            name="address"
            fullWidth
            value={deliveryDetails.address}
            onChange={handleChange(setDeliveryDetails)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contact Name"
            name="contactName"
            fullWidth
            value={deliveryDetails.contactName}
            onChange={handleChange(setDeliveryDetails)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Contact Phone"
            name="contactPhone"
            fullWidth
            value={deliveryDetails.contactPhone}
            onChange={handleChange(setDeliveryDetails)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Delivery Date"
            name="deliveryDate"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={deliveryDetails.deliveryDate}
            onChange={handleChange(setDeliveryDetails)}
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: '24px 0' }} />

      <Typography variant="h5" gutterBottom>
        Additional Notes
      </Typography>
      <TextField
        label="Notes"
        name="additionalNotes"
        fullWidth
        multiline
        rows={4}
        value={additionalNotes}
        onChange={(e) => setAdditionalNotes(e.target.value)}
      />

      <Divider style={{ margin: '24px 0' }} />

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit Transportation Request
      </Button>
    </Container>
  );
}

export default TransportationRequestForm;
