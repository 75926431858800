import React from 'react';
import { Handle, Position } from 'reactflow';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const ActionEventNodeContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  border: '1px solid #333',
  padding: theme.spacing(0.5),
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 0,
  overflow: 'visible',  // Ensure handles are not cut off
  wordWrap: 'break-word',
}));

export const ActionEventNodeSize = {
  width: 256,
  height: 64,
};

const ActionEventNode = ({ data }) => {
  const { label, width = ActionEventNodeSize.width, height = ActionEventNodeSize.height, fill = 'white', borderRadius = 2, state = "DEFAULT" } = data;

  return (
    <ActionEventNodeContainer sx={{ width, height, backgroundColor: fill, borderRadius }}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={width} height={height} fill={fill} rx={borderRadius} />
        <foreignObject x="0" y="0" width={width} height={height} overflow={'hidden'}>
          <div xmlns="http://www.w3.org/1999/xhtml" style={{ wordWrap: 'break-word', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '4px', boxSizing: 'border-box', textAlign: 'center', color: 'black' }}>
            {label}
          </div>
        </foreignObject>
      </svg>
      <Handle
        id="left"
        type="target"
        position={Position.Left}
        style={{ background: '#555', top: '50%', borderRadius: '0', zIndex: 1, transform: 'translateY(-50%)', left: '-6px' }}
      />
      <Handle
        id="right"
        type="target"
        position={Position.Right}
        style={{ background: '#555', top: '50%', borderRadius: '0', zIndex: 1, transform: 'translateY(-50%)', right: '-6px' }}
      />
    </ActionEventNodeContainer>
  );
};

export default ActionEventNode;
