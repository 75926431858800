import { useEffect, useState } from 'react';
import Axios from 'axios';
import { useAuth } from '../contexts/AuthContext/AuthContext.jsx';

const useLifecycleDataFetch = ({ url }) => {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {

    async function fetchData() {

      if (token) {
        try {
          const response = await Axios.get(url, {
            headers: {
              Authorization: `JWT ${token}`,
            },
          });
          // loop through all keys and see if they are empty objects, if so delete them from the response
          let retVal = response.data;
          setData(retVal);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle error appropriately
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }

    }

    fetchData();
  }, [token, url]);

  return { isLoading, data };
};

export default useLifecycleDataFetch;
