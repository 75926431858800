import React from 'react';
import { Drawer, Box, Button, Typography, TextField } from '@mui/material';
import { styled } from '@mui/system';

const DrawerContent = styled(Box)(({ theme }) => ({
  width: 300,
  height: '100%',
  padding: theme.spacing(2.5),
  boxShadow: theme.shadows[5],
  backgroundColor: theme.palette.background.paper,
}));

const PropertiesPanel = ({ node, onClose, onUpdate }) => {
  if (!node) return null;

  const handleChange = (event) => {
    const { name, value } = event.target;
    onUpdate({ ...node, data: { ...node.data, [name]: value } });
  };

  return (
    <Drawer
      anchor="right"
      open={Boolean(node)}
      onClose={onClose}
      variant="persistent"
    >
      <DrawerContent>
        <Button variant="contained" color="secondary" onClick={onClose}>
          Close
        </Button>
        <Typography variant="h6" gutterBottom>
          Edit Node
        </Typography>
        <TextField
          fullWidth
          label="Label"
          name="label"
          value={node.data.label}
          onChange={handleChange}
          margin="normal"
        />
      </DrawerContent>
    </Drawer>
  );
};

export default PropertiesPanel;
