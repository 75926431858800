import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
// import Cookies from 'js-cookie'

const PlatformOneEmbed = ({ authToken }) => {
  const { id: tenantId } = useParams();
  console.log("Test", tenantId)
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        backgroundColor: 'white',
      }}
    >
      <iframe
        title="Lifecycle Workflow Manager"
        src={`https://${tenantId}.ridecell.io/platform-one/`}
        width="100%"
        height="100%"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
          backgroundColor: 'white',
        }}
      />
    </div>
  )
}

export default PlatformOneEmbed
