import axios from 'axios';

export const getDataSources = async ({ token }) => {
  const response = await axios.get('/api/v1/data-source/', {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
  return response.data;
};

export const addDataSource = async ({ dataSource, token }) => {
  await axios.post('/api/v1/data-source/', dataSource, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
};

export const updateDataSource = async ({ dataSource, token }) => {
  await axios.patch(`/api/v1/data-source/${dataSource.id}/`, dataSource, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
};
