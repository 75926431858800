import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ReviewAndSubmitForm({
  handleSubmit,
  vehicleDetails,
  specifications,
  upfits,
  orderDetails,
  keyDates,
}) {
  return (
    <div>
      <Typography variant="h4" paragraph>
        Review & Submit
      </Typography>

      <Divider sx={{ marginBottom: '24px' }} />

      {/* Vehicle Details Accordion */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Vehicle Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>VIN: {vehicleDetails.vin}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>License Plate: {vehicleDetails.license_plate.number}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>State: {vehicleDetails.license_plate.state}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Expiration Date: {vehicleDetails.license_plate.expiration_date}</Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Order Details Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Order Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Status: {orderDetails.status}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Expected Delivery: {orderDetails.expected_delivery_date}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Net Price: ${orderDetails.net_price}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Additional Details</Typography>
              {Object.entries(orderDetails.additional_details).map(([key, value]) => (
                <Typography key={key}>
                  {key.replace(/_/g, ' ')}: {value || 'N/A'}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Specifications Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Specifications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Make: {specifications.make}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Model: {specifications.model}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Year: {specifications.model_year}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Color: {specifications.color}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Details</Typography>
              {Object.entries(specifications.details).map(([key, value]) => (
                <Typography key={key}>
                  {key.replace(/_/g, ' ')}: {value || 'N/A'}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Upfits Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Upfits / Modifications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {Object.entries(upfits).map(([key, value]) => (
              <Grid item xs={6} key={key}>
                <Typography>
                  {key.replace(/_/g, ' ')}: {value ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Key Dates Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Key Dates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {Object.entries(keyDates).map(([key, value]) => (
              <Grid item xs={6} key={key}>
                <Typography>
                  {key.replace(/_/g, ' ')}: {value || 'N/A'}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Divider style={{ margin: '24px 0' }} />

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
}

export default ReviewAndSubmitForm;
