import React from 'react';
import { Handle } from 'reactflow';
import PropTypes from 'prop-types';
import ArrowCycle from '../../assets/ArrowCycle.png'; // Import the PNG

// Custom circle with arrows node component using PNG
const CircleWithArrowsNode = ({ data }) => {
  const { width = 560, height = 560 } = data; // Default values if not provided

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none', // Ensures the node is not clickable
      }}
    >
      {/* Replace SVG with the PNG image */}
      <img
        src={ArrowCycle}
        alt="Arrow Cycle"
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain', // Ensures the image fits the box without distortion
          pointerEvents: 'none', // Ensures the node is not clickable
        }}
      />

    </div>
  );
};

CircleWithArrowsNode.propTypes = {
  data: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default CircleWithArrowsNode;
