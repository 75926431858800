import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer({ className }) {
  return (
    <div className={className}>
      {/* 'linear-gradient(180deg, #00B5E2 2%, #6CC04A 100%)' */}
      <Box component="footer" sx={{ p: 2, background: '#6CC04A', color: 'white', textAlign: 'center' }}>
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} Ridecell. All rights reserved.
        </Typography>
      </Box>
    </div>
  );
}

export default Footer;
