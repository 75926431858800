import React, { useState } from 'react';
import { Grid, Chip, Button, Box } from '@mui/material';
import FindInPage from '@mui/icons-material/FindInPage';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const IntelligenceFilterAndActionsBar = ({ didChangeFleetFilter = () => { }, didChangeIntelligenceDataView = () => { } }) => {

  const [selectedGroup1, setSelectedGroup1] = useState('Flex'); // Initialize with a single value
  const [selectedGroup2, setSelectedGroup2] = useState('Idle Time'); // Initialize with a single value

  const handleChipClick = (chip, group) => {
    if (group === 1) {
      setSelectedGroup1(chip); // Directly set the clicked chip as the selected one
      didChangeFleetFilter(chip);
    } else {
      setSelectedGroup2(chip); // Directly set the clicked chip as the selected one
      didChangeIntelligenceDataView(chip);
    }
  };

  return (
    <Grid container spacing={1} alignItems="center" style={{ marginBottom: '-16px', padding: '8px', backgroundColor: '#fafafa' }}>
      <Grid item xs={8}>
        <Grid container spacing={1} direction="column">
          {/* Group 1 */}
          <Grid item>
            <Grid container spacing={1}>
              {['Total Fleet', 'LTR', 'Flex', 'Re-Lease', 'Amazon'].map((label) => (
                <Grid item key={label}>
                  <Chip
                    label={label}
                    variant={selectedGroup1 === label ? 'filled' : 'outlined'}
                    color={selectedGroup1 === label ? 'success' : 'default'}
                    onClick={() => handleChipClick(label, 1)} // Select only one chip
                    style={{
                      backgroundColor: selectedGroup1 === label ? '#00965a' : '#ffffff',
                      textTransform: 'none',
                      textAlign: 'center',
                      fontFeatureSettings: 'liga off clig off',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '175%',
                      letterSpacing: '0.15px',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Group 2 */}
          <Grid item>
            <Grid container spacing={1}>
              {['Utilization Rate', 'Idle Time', 'Profitability', 'Reference Mix', 'Age'].map((label) => (
                <Grid item key={label}>
                  <Chip
                    label={label}
                    variant={selectedGroup2 === label ? 'filled' : 'outlined'}
                    color={selectedGroup2 === label ? 'success' : 'default'}
                    onClick={() => handleChipClick(label, 2)} // Select only one chip
                    style={{
                      backgroundColor: selectedGroup2 === label ? '#00965a' : '#ffffff',
                      textTransform: 'none',
                      textAlign: 'center',
                      fontFeatureSettings: 'liga off clig off',
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '175%',
                      letterSpacing: '0.15px',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Actions */}
      <Grid item xs={4} container justifyContent="flex-end">
        <Box>
          <Button
            variant="outlined"
            startIcon={<FindInPage />}
            sx={{
              marginRight: '8px',
              textTransform: 'none',
              textAlign: 'center',
              fontFeatureSettings: 'liga off clig off',
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '160%',
              letterSpacing: '0.15px',
              height: '44px',
              width: '155px',
            }}
          >
            View Table
          </Button>
          <Button
            variant="outlined"
            startIcon={<CloudDownloadIcon />}
            sx={{
              height: '44px',
              textTransform: 'none',
              textAlign: 'center',
              fontFeatureSettings: 'liga off clig off',
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '160%',
              letterSpacing: '0.15px',
              width: '213px',
            }}
          >
            Download Report
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default IntelligenceFilterAndActionsBar;
