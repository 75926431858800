import React, { useState, useRef, useCallback, useEffect } from 'react';

import ReactFlow, {
  addEdge,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  useReactFlow
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Box } from '@mui/material';


// Nodes
import StageNode from '../Nodes/StageNode.js';
import StepNode from '../Nodes/StepNode.js';
import ActionEventNode from '../Nodes/ActionEventNode.js';

// Components
import PropertiesPanel from './PropertiesPanel.js';
import DashedEdge from './DashedEdge.js';

// Utils
import { StageNodeSize } from '../Nodes/StageNode.js';
import { StepNodeSize } from '../Nodes/StepNode.js';
import { ActionEventNodeSize } from '../Nodes/ActionEventNode.js';

const edgeTypes = {
  customDashed: DashedEdge,
};

const nodeTypes = {
  stageNode: StageNode,
  stepNode: StepNode,
  actionEventNode: ActionEventNode,
};

function WorkflowManager({
  lifecycleData,
}) {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedElements, setSelectedElements] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const { project } = useReactFlow();

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode(node);
  }, []);

  const closePanel = useCallback(() => {
    setSelectedNode(null);
  }, []);

  const updateNode = useCallback((updatedNode) => {
    setNodes((nds) => nds.map(node => node.id === updatedNode.id ? updatedNode : node));
  }, [setNodes]);

  const onConnect = useCallback((params) => {
    // console.log("Edge connected:", params);
    setEdges((eds) => addEdge(params, eds));
  }, [setEdges]);

  const onElementsSelect = useCallback((elements) => {
    // console.log("Elements selected:", elements);
    setSelectedElements(elements);
  }, []);

  const onDrop = useCallback((event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    const newNode = {
      id: `node-${new Date().getTime()}`,
      type,
      position,
      data: { label: `${type} node` },
    };

    console.log("Adding new node:", newNode);
    setNodes((nds) => nds.concat(newNode));
  }, [project, setNodes]);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onKeyDown = useCallback((event) => {
    console.log({
      onKeyDown: event.key,
      selectedElements,
    });
    if ((event.key === 'Delete' || event.key === 'Backspace') && selectedElements) {
      const nodeIds = selectedElements.map((el) => el.id);
      console.log("Deleting nodes:", nodeIds);
      setNodes((nds) => nds.filter((n) => !nodeIds.includes(n.id)));
      setEdges((eds) => eds.filter((e) => !nodeIds.includes(e.source) && !nodeIds.includes(e.target)));
    }
  }, [selectedElements, setNodes, setEdges]);

  const toHumanReadable = str => {
    return str
      .toLowerCase() // Convert the entire string to lowercase
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
  }

  const preLoadData = () => {
    console.log({
      preLoadData: lifecycleData,
    })
    if (!lifecycleData) { return; }
    // Given the data from the Data file, we need to load the nodes and edges
    let retNodes = [];
    let retEdges = [];

    const allStageLinks = lifecycleData.stageLinks;
    // TODO: We need to handle multiple templates per tenant
    const allStages = lifecycleData.lifecycleTemplates[0].life_cycle_stages?.sort((a, b) => {

      if (a.is_first_stage) return -1;
      if (b.is_first_stage) return -1;
      if (a.is_cycle_end) return 1;
      if (b.is_cycle_end) return 1;
      return a.id - b.id;

    });

    // First will be creating the nodes for stages
    allStages.forEach((stage, stageIndex) => {
      const position = project({
        x: (stageIndex * (StageNodeSize.width + ActionEventNodeSize.width + 32)),
        y: 0,
      });
      const newNode = {
        id: `stage-${stage.id}`,
        type: 'stageNode',
        position,
        data: { label: stage.name },
      };
      retNodes.push(newNode);
      // now to create the step nodes in each stage template
      stage.life_cycle_steps.forEach((step, stepIndex) => {
        // Then need to be positioned below the stage centered vertically
        const stepPosition = project({
          x: (position.x + (StageNodeSize.width / 2) - (StepNodeSize.width / 2)),
          y: 32 + (StageNodeSize.height) + (stepIndex * (StepNodeSize.height + 32)),
        });
        const newStepNode = {
          id: `step-${step.id}`,
          type: 'stepNode',
          position: stepPosition,
          data: { label: step.name },
        };
        retNodes.push(newStepNode);

        // Next we need to configure the action event nodes for each step
        const actionEvent = step.life_cycle_action_event;
        if (actionEvent) {
          const yPos = stepPosition.y + (ActionEventNodeSize.height / 2);
          // Need to be centered horizontally in the step node
          let xPos = stepPosition.x;
          switch (actionEvent.direction) {
            case 'OUT_BOUND':
              xPos += StepNodeSize.width + 32;
              break;
            case 'IN_BOUND':
              xPos -= StepNodeSize.width + 32;
              break;
            case 'INTERNAL':
              xPos += StepNodeSize.width + 32;
              break;
            default:
              break;
          }
          const actionEventPosition = project({
            x: xPos,
            y: yPos,
          });
          const newActionEventNode = {
            id: `action-event-${actionEvent.id}-step-${step.id}`,
            type: 'actionEventNode',
            position: actionEventPosition,
            data: { label: `${toHumanReadable(actionEvent.event)} | ${actionEvent.direction}` },
          };
          retNodes.push(newActionEventNode);

          // Create an edge between the step and action event nodes
          // if the direction is OUT_BOUND the action needs to connect on the right side of the step node using the actions left handle
          // if the direction is IN_BOUND the action needs to connect on the left side of the step node using the actions right handle
          // if the direction is INTERNAL the action need to connect on the bottom side of the step node using the actions top handle
          let actionEventEdge = null;
          switch (actionEvent.direction) {
            case 'OUT_BOUND':
              actionEventEdge = {
                id: `action-event-edge-${actionEvent.id}-outbound-${step.id}`,
                source: newStepNode.id,
                sourceHandle: 'right',
                target: newActionEventNode.id,
                targetHandle: 'left',
                animated: false,
                style: { stroke: 'black' },
              };
              break;
            case 'IN_BOUND':
              actionEventEdge = {
                id: `action-event-edge-${actionEvent.id}-inbound-${step.id}`,
                source: newStepNode.id,
                sourceHandle: 'left',
                target: newActionEventNode.id,
                targetHandle: 'right',
                animated: false,
                style: { stroke: 'black' },
              };
              break;
            case 'INTERNAL':
              actionEventEdge = {
                id: `action-event-edge-${actionEvent.id}-internal-${step.id}`,
                source: newStepNode.id,
                sourceHandle: 'right',
                target: newActionEventNode.id,
                targetHandle: 'left',
                animated: false,
                style: { stroke: 'black' },
              };
              break;
            default:
              break;
          }
          if (actionEventEdge) {
            retEdges.push(actionEventEdge);
          }

        }

        // Configure the edges between the stage and step nodes
        const newEdge = {
          id: `edge-${newStepNode.id}`,
          source: newNode.id,
          sourceHandle: 'bottom', // Bottom handle of the stage node
          target: newStepNode.id,
          targetHandle: 'top', // Top handle of the step node
          animated: false,
          style: { stroke: 'black' },
        };
        retEdges.push(newEdge);


      });
      // Configure the edges between the stage nodes using the stageLinks as reference
      // First filter all the stage links that match this stages id
      allStageLinks.filter((link) => link.from_stage === stage.id).forEach((link) => {
        const isForwardDirection = link.link_direction === 1;
        const stageLinkEdge = {
          id: `stage-link-${link.id}`,
          source: `${newNode.id}`,
          sourceHandle: isForwardDirection ? 'right' : 'left', // Bottom handle of the stage node
          target: `stage-${link.to_stage}`,
          targetHandle: isForwardDirection ? 'left' : 'right', // Top handle of the step node
          animated: true,
          style: { stroke: 'black' },
        };
        retEdges.push(stageLinkEdge);
      });
    });

    return { nodes: retNodes, edges: retEdges };
  };


  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  // // useEffect to call preLoadData only once on component mount
  useEffect(() => {
    (() => {
      if (!lifecycleData) { return; }
      const { nodes, edges } = preLoadData();
      setNodes(nodes);
      setEdges(edges);
    })()
  }, [lifecycleData]); // Empty dependency array ensures this runs only once

  return (
    <Box ref={reactFlowWrapper} sx={{ height: '100%', width: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onNodeClick={onNodeClick}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        onDrop={onDrop}
        onDragOver={onDragOver}
        snapGrid={[8, 8]}
        snapToGrid
        fitView
      >
        <Controls />
        <Background color="#aaa" gap={8} />
      </ReactFlow>
      {selectedNode && (
        <PropertiesPanel node={selectedNode} onClose={closePanel} onUpdate={updateNode} />
      )}
    </Box>
  );
}

export default WorkflowManager;
