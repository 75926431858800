import React from 'react';
import { CircularProgress, Box } from '@mui/material';

function LoadingIndicator({ isLoading }) {
  if (!isLoading) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 1000,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default LoadingIndicator;
