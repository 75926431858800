import React from 'react';
import { Container, Typography } from '@mui/material';

function Home() {
  return (
    <Container>
      <Typography paragraph variant='h4'>Welcome to the SUI-Tool!</Typography>
      <Typography paragraph>
        This application is designed to be used by the solutions and sales team in order to provide a more complete experience to our customers
        This application will allow you to demonstrate the various processes that are available in the Ridecell platform.
        It will also support common tasks by the Solutions team in an effort to automate and streamline the process of demos.
      </Typography>
      <Typography paragraph>
        The application is still in development and new features will be added over time. It is currently being maintained by Brent Scarafone (#scarafone).
      </Typography>
      <Typography paragraph variant='h5'>
        Roadmap
      </Typography>
      <ul>
        <li><b>Tenant Processors</b></li>
        <ul>
          <li>Add support for demonstrating the contract creation process</li>
          <li>Add support for demonstrating the transport process</li>
          <li>Add support for demonstrating the vehicle order process</li>
          <li>Add support for Lifecycle Workflow Management</li>
        </ul>
        <li><b>Data Processors</b></li>
        <ul>
          <li>Expose ability to get tenant data</li>
          <li>Add ability to transplant data from a source tenant to a target tenant</li>
          <li>Expose ability to set up, fetch and configure data sources</li>
        </ul>
      </ul>
    </Container>
  );
}

export default Home;
