import React from 'react';
import { Grid, TextField, Button, Typography, MenuItem } from '@mui/material';
import { v4 as uuidv4 } from 'uuid'; // For generating XID

const statusOptions = ['NEW', 'Order Accepted', 'Pending', 'Delivered']; // Add more status options as needed

function AdditionalDetailsForm({ orderDetails, setOrderDetails }) {

  // Handles input changes dynamically based on field type
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'xid') {
      setOrderDetails((prevDetails) => ({
        ...prevDetails,
        xid: `order-xid-${value}`,
      }));
    } else {
      setOrderDetails((prevDetails) => ({
        ...prevDetails,
        additional_details: {
          ...prevDetails.additional_details,
          [name]: value,
        },
      }));
    }
  };

  // Handles status change separately
  const handleStatusChange = (e) => {
    const { value } = e.target;
    setOrderDetails((prevDetails) => ({
      ...prevDetails,
      status: value,
    }));
  };

  // Populates random values for both dates and strings
  const populateRandomValues = () => {
    const randomDate = () => new Date(Date.now() - Math.floor(Math.random() * 1e10))
      .toISOString().slice(0, 10); // Format YYYY-MM-DD

    setOrderDetails({
      xid: `order-xid-${uuidv4().split('-').shift()}`, // Generate a new XID
      status: 'NEW', // Default status
      additional_details: {
        arrived_to_dealer_date: randomDate(),
        cd_fee_included_in_invoice: Math.random() > 0.5 ? 'Yes' : 'No',
        courtesy_delivery_fee_paid_date: randomDate(),
        eta_to_dealer: randomDate(),
        last_event_date: randomDate(),
        manufacturer_accepted_date: randomDate(),
        manufacturer_rejected_date: randomDate(),
        paperwork_received_date: randomDate(),
        requested_target_production_week: '2023-W52',
        shipping_method: 'Ground',
        target_production_week: '2023-W52',
        vehicle_scheduled_for_production_date: randomDate(),
        shipped_date: randomDate(),
      },
    });
  };

  // Determines if a field should be treated as a date input
  const isDateField = (fieldName) => fieldName.includes('date') || fieldName.includes('week');

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Additional Order Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Order XID"
            name="xid"
            value={orderDetails.xid}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label="Order Status"
            value={orderDetails.status || 'NEW'}
            onChange={handleStatusChange}
            variant="outlined"
          >
            {statusOptions.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {Object.entries(orderDetails.additional_details).map(([key, value]) => (
          <Grid item xs={12} sm={6} key={key}>
            <TextField
              fullWidth
              label={key.replace(/_/g, ' ')}
              name={key}
              value={value || ''}
              onChange={handleInputChange}
              variant="outlined"
              type={isDateField(key) ? 'date' : 'text'}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={populateRandomValues}>
            Populate Random Values
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default AdditionalDetailsForm;
