import React from 'react';
import { Handle, Position } from 'reactflow';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const StepNodeContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  border: '1px solid #333',
  padding: theme.spacing(0.5),
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  overflow: 'visible',  // Ensure handles are not cut off
  wordWrap: 'break-word',
}));

const handleStyle = {
  width: '12px',
  height: '12px',
  background: '#555',
  borderRadius: '0',
  zIndex: 1,  // Ensure handles are above the node
};

export const StepNodeSize = {
  width: 256,
  height: 128,
};

const StepNode = ({ data }) => {
  const { label, width = StepNodeSize.width, height = StepNodeSize.height, fill = 'white', borderRadius = 2 } = data;

  return (
    <StepNodeContainer sx={{ width, height, backgroundColor: fill, borderRadius }}>
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width={width} height={height} fill={fill} rx={borderRadius} />
        <foreignObject x="0" y="0" width={width} height={height}>
          <div xmlns="http://www.w3.org/1999/xhtml" style={{ wordWrap: 'break-word', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '4px', boxSizing: 'border-box', textAlign: 'center', color: 'black' }}>
            {label}
          </div>
        </foreignObject>
      </svg>
      <Handle
        type="target"
        position={Position.Top}
        id="top"
        style={{ ...handleStyle, top: '-6px', left: '50%', transform: 'translateX(-50%)' }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="right"
        style={{ ...handleStyle, right: '-6px', top: '50%', transform: 'translateY(-50%)' }}
      />
      <Handle
        type="source"
        position={Position.Left}
        id="left"
        style={{ ...handleStyle, left: '-6px', top: '50%', transform: 'translateY(-50%)' }}
      />
    </StepNodeContainer>
  );
};

export default StepNode;
