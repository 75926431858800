import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Grid, Fab } from '@mui/material';
import { Handle } from 'reactflow';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import IntelligenceDialogWindow from './IntelligenceDialogWindow';

const ColorMappings = {
  yellow: '#ffc400',
  red: '#ff6575',
  green: '#9ddba0',
};

const CustomNode = ({ data }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const trendIndicatorDirection = data.indicator.direction;
  const trendIndicatorColor = ColorMappings[data.indicator.color];
  const handles = data.handles || {};
  const intelligenceDataView = data.intelligenceDataView;

  const nodeCardFormat = () => {
    return (
      <Card
        style={{
          maxWidth: '300px',
          maxHeight: '160px',
          textAlign: 'center',
          padding: '4px',
          borderRadius: '36px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)', // Add shadow around all edges
          cursor: 'pointer',
        }}
        onClick={handleOpen}
      >
        <CardContent>
          <Grid container alignItems="center" spacing={1}>
            {/* Title Row with FAB */}
            <Grid item xs={12} >
              <Typography variant="subtitle2" style={{
                color: '#000',
                textAlign: 'center',
                fontFeatureSettings: 'liga off clig off',
                fontFamily: 'Roboto',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '116.7%',
              }}>
                {data.title}
              </Typography>
            </Grid>
            <Fab
              size="small"
              onClick={handleOpen}
              style={{
                backgroundColor: 'white',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                position: 'absolute',
                top: '4px',
                right: '18px',
                color: '#00965a',
                width: '34px',
                height: '34px',
              }}
            >
              <ZoomOutMapIcon style={{ fontSize: '18px', color: '#666' }} />
            </Fab>

            {/* Top Row: Avg Idle/Rate and Trend */}
            <Grid container alignItems="center" spacing={1} style={{ marginBottom: '-16px', marginTop: '8px' }}>
              <Grid item xs={3} style={{ textAlign: 'left' }}>
                <Typography variant="caption" style={{
                  color: '#00000099',
                }}>
                  {intelligenceDataView === 'Idle Time' ? 'Avg Idle' : 'Rate'}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'left' }}>
                <Typography variant="h5" style={{
                  color: '#2f2e2f',
                  textAlign: 'center',
                  fontFeatureSettings: 'liga off clig off',
                  fontFamily: 'Roboto',
                  fontSize: '32px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '116.7%',
                  marginLeft: intelligenceDataView === 'Idle Time' ? '-12px' : '-18px',
                }}>
                  {data.avgIdle}
                </Typography>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'left' }}>
                <Typography variant="caption" color="textSecondary" component="span" style={{
                  fontWeight: '400',
                  color: '#00000099',
                  textAlign: 'center',
                  fontFeatureSettings: 'liga off clig off',
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '150%',
                  marginLeft: intelligenceDataView === 'Idle Time' ? '-12px' : '-18px',
                }}>
                  {intelligenceDataView === 'Idle Time' ? 'Days' : '%'}
                </Typography>
              </Grid>

              <Grid item xs={1} >
                <Box display="flex" color={trendIndicatorColor} style={{ marginLeft: '0px' }}>
                  {trendIndicatorDirection === 'arrow_up' ? (
                    <TrendingUpIcon style={{ fontSize: '24px', color: trendIndicatorColor }} />
                  ) : (
                    <TrendingDownIcon style={{ fontSize: '24px', color: trendIndicatorColor }} />
                  )}
                </Box>
              </Grid>

              <Grid item xs={2}>
                <Typography variant="caption" style={{
                  fontSize: '24px',
                  color: trendIndicatorColor,
                  marginRight: '-36px',
                  textAlign: 'center',
                  fontFeatureSettings: 'liga off clig off',
                  fontFamily: 'Roboto',
                  fontSize: '32px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '116.7%',
                }}>
                  {data.trend}%
                </Typography>
              </Grid>
            </Grid>

            {/* Divider */}
            <Grid item xs={14} height={'12px'} style={{
              borderBottom: '1px solid #0000001A',
              marginTop: '8px',
              marginBottom: '8px',
            }}>
            </Grid>

            {/* Bottom Row: Throughput/Avg Vehicles */}
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={4} alignContent="center" style={{ textAlign: 'left' }}>
                <Typography variant="caption" style={{
                  color: '#00000099',
                  fontSize: '24px',
                  marginRight: '-36px',
                  textAlign: 'center',
                  fontFeatureSettings: 'liga off clig off',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '150%',
                }}>
                  {intelligenceDataView === 'Idle Time' ? 'Throughput' : 'Avg Vehicles'}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Typography variant="h4" style={{
                  color: '#2F2E2F',
                  fontSize: '24px',
                  marginRight: '-18px',
                  textAlign: 'center',
                  fontFeatureSettings: 'liga off clig off',
                  fontFamily: 'Roboto',
                  fontSize: '32px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '116.7%',
                }}>
                  {data.throughput}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'left' }}>
                <Typography variant="caption" component="span" style={{
                  color: '#00000099',
                  fontSize: '24px',
                  textAlign: 'center',
                  fontFeatureSettings: 'liga off clig off',
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '150%',
                }}>
                  Vehicles
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card >
    );
  };


  return (
    <>
      {/* Handles for connections */}
      {handles.top?.enabled && (
        <Handle
          id="top"
          type={handles.top.type}
          position="top"
          style={{
            background: 'transparent',
            border: 'none',
            width: '10px',
            height: '10px',
          }}
        />
      )}
      {handles.bottom?.enabled && (
        <Handle
          id="bottom"
          type={handles.bottom.type}
          position="bottom"
          style={{
            background: 'transparent',
            border: 'none',
            width: '10px',
            height: '10px',
          }}
        />
      )}
      {handles.left?.enabled && (
        <Handle
          id="left"
          type={handles.left.type}
          position="left"
          style={{
            background: 'transparent',
            border: 'none',
            width: '10px',
            height: '10px',
          }}
        />
      )}
      {handles.right?.enabled && (
        <Handle
          id="right"
          type={handles.right.type}
          position="right"
          style={{
            background: 'transparent',
            border: 'none',
            width: '10px',
            height: '10px',
          }}
        />
      )}

      {nodeCardFormat()}
      <IntelligenceDialogWindow open={open} handleClose={handleClose} data={data} intelligenceDataView={intelligenceDataView} />
    </>
  );
};

export default CustomNode;
