import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Grid, Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const ColorMappings = {
  yellow: '#ffc400',
  red: '#ff6575',
  green: '#9ddba0',
};

const IntelligenceDialogWindow = ({ data, handleClose, open, intelligenceDataView }) => {

  const navigate = useNavigate();
  const { id } = useParams();

  const getStages = () => {
    return data.stages
      .filter(s => !s.is_disruption_stage)
      .map(item => getFormattedStage(item));
  };

  const getDisruptionStages = () => {
    return data.stages
      ?.filter(s => s.is_disruption_stage)
      .map(item => getFormattedStage(item));
  };

  const didSelectActionButton = (index, stage) => {
    if (index === 0) {
      window.open(`https://starflightesam-uat.ridecell.com${stage.ref.orchestration_uri}`, '_blank');
    } else {
      navigate(`${stage.season}/${stage.stage}/dashboard?embedded=true`);
      handleClose();
    }
  };



  const getFormattedStage = (stage) => {
    return {
      season: data.title,
      stage: stage.name,
      avgIdle: (intelligenceDataView === 'Idle Time' ? stage.metrics?.idle_days.toFixed(1) : stage.metrics?.operational_rate.toFixed(1) || 0),
      throughput: (stage.metrics?.throughput || 0).toFixed(0),
      trend: `${Math.floor((intelligenceDataView === 'Idle Time' ? stage.metrics_change_percent?.idle_days : stage.metrics_change_percent?.operational_rate) * 100).toFixed(0)}%`,
      actions: [<DeviceHubIcon />, <FindInPageIcon />],
      indicator: {
        direction: intelligenceDataView === 'Idle Time' ? stage?.metrics_change_indicator.idle_days.icon : stage.metrics_change_indicator?.operational_rate.icon,
        color: intelligenceDataView === 'Idle Time' ? stage?.metrics_change_indicator.idle_days.color : stage.metrics_change_indicator?.operational_rate.color,
      },
      ref: stage,
    };
  };

  const disruptionStages = getDisruptionStages();
  const stages = getStages();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      PaperProps={{
        style: {
          borderRadius: '4px',
          maxWidth: '1024px',
          width: '90%',
          margin: 'auto',
          padding: '20px',
        },
      }}
    >
      <DialogTitle
        id="modal-title"
        style={{
          color: '#000',
          textAlign: 'center',
          fontFeatureSettings: 'liga off clig off',
          fontFamily: 'Roboto',
          fontSize: '20px',
          fontWeight: 400,
          lineHeight: '160%',
        }}
      >
        {data.title}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>

          {/* Headers */}
          <Grid item xs={6}>
            <Typography variant="subtitle1" style={headerStyle}>
              Stage
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" style={headerStyle}>
              {intelligenceDataView === 'Idle Time' ? 'Avg Idle (Days)' : 'Rate (%)'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1" style={headerStyle}>
              {intelligenceDataView === 'Idle Time' ? 'Throughput (Vehicles)' : 'Avg Vehicles'}
            </Typography>
          </Grid>

          {/* Rows/Stages */}
          {stages.map((item, index) => (
            <React.Fragment key={index}>
              <Grid container alignItems="center" spacing={1}>
                {/* Stage Name with Actions Inline */}
                <Grid item xs={6} container alignItems="center" spacing={1}>
                  <Grid item>
                    <Typography variant="body1" style={stageTextStyle}>
                      {item.stage}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      {item.actions.map((IconComponent, idx) => (
                        <Grid item key={idx}>
                          <Button variant="outlined" size="small" style={{ color: '#00965a' }} onClick={() => didSelectActionButton(idx, item)} >
                            {IconComponent}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Avg Idle / Rate with Trend Indicator */}
                <Grid item xs={4} container alignItems="center">
                  <Typography variant="body1" style={valueTextStyle}>
                    {item.avgIdle}
                  </Typography>
                  <Grid item>
                    {item.indicator.direction === 'arrow_up' ? (
                      <TrendingUpIcon
                        style={{ fontSize: '24px', color: ColorMappings[item.indicator.color], marginLeft: '10px', }}
                      />
                    ) : (
                      <TrendingDownIcon
                        style={{ fontSize: '24px', color: ColorMappings[item.indicator.color], marginLeft: '10px', }}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" style={{
                      color: ColorMappings[item.indicator.color],
                      textAlign: 'center',
                      fontFeatureSettings: 'liga off clig off',
                      fontFamily: 'Roboto',
                      fontSize: '32px',
                      fontWeight: 400,
                      lineHeight: '160%',
                      paddingLeft: '10px',
                    }}>
                      {item.trend}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Throughput / Avg Vehicles */}
                <Grid item xs={2}>
                  <Typography variant="h4" style={throughputTextStyle}>
                    {item.throughput}
                  </Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>

        {/* Disruption Stages */}
        {disruptionStages.length > 0 && (
          <Grid container spacing={1} style={{
            marginTop: '20px',
          }}>
            {/* Headers */}
            <Grid item xs={6}>
              <Typography variant="subtitle1" style={{
                ...headerStyle,
                marginTop: '18px',
              }}>
                Disruption Stage
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" style={headerStyle}>
                {''}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle1" style={headerStyle}>
                {''}
              </Typography>
            </Grid>

            {/* Rows/Stages */}
            {disruptionStages.map((item, index) => (
              <React.Fragment key={index}>
                <Grid container alignItems="center" spacing={1}>
                  {/* Stage Name with Actions Inline */}
                  <Grid item xs={6} container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography variant="body1" style={stageTextStyle}>
                        {item.stage}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container spacing={1}>
                        {item.actions.map((IconComponent, idx) => (
                          <Grid item key={idx}>
                            <Button variant="outlined" size="small" style={{ color: '#00965A' }} onClick={() => didSelectActionButton(idx, item)} >
                              {IconComponent}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Avg Idle / Rate with Trend Indicator */}
                  <Grid item xs={4} container alignItems="center">
                    <Typography variant="body1" style={valueTextStyle}>
                      {item.avgIdle}
                    </Typography>
                    <Grid item>
                      {item.indicator.direction === 'arrow_up' ? (
                        <TrendingUpIcon
                          style={{ fontSize: '24px', color: ColorMappings[item.indicator.color], marginLeft: '10px', }}
                        />
                      ) : (
                        <TrendingDownIcon
                          style={{ fontSize: '24px', color: ColorMappings[item.indicator.color], marginLeft: '10px', }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" style={{
                        color: ColorMappings[item.indicator.color],
                        textAlign: 'center',
                        fontFeatureSettings: 'liga off clig off',
                        fontFamily: 'Roboto',
                        fontSize: '32px',
                        fontWeight: 400,
                        lineHeight: '160%',
                        paddingLeft: '10px',
                      }}>
                        {item.trend}
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* Throughput / Avg Vehicles */}
                  <Grid item xs={2}>
                    <Typography variant="h4" style={throughputTextStyle}>
                      {item.throughput}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

const headerStyle = {
  color: '#00000099',
  fontFamily: 'Roboto',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  textAlign: 'left',
  marginLeft: '-8px'
};

const stageTextStyle = {
  fontWeight: '600',
  color: '#2f2e2f',
  fontFeatureSettings: 'liga off clig off',
  fontFamily: 'Roboto',
  fontSize: '20px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  textAlign: 'left',
};

const valueTextStyle = {
  fontWeight: '400',
  color: '#2f2e2f',
  fontFeatureSettings: 'liga off clig off',
  fontFamily: 'Roboto',
  fontSize: '32px',
  lineHeight: '116.7%',
  textAlign: 'center',
};

const throughputTextStyle = {
  fontWeight: '400',
  color: '#2f2e2f',
  fontFeatureSettings: 'liga off clig off',
  fontFamily: 'Roboto',
  fontSize: '32px',
  lineHeight: '116.7%',
  textAlign: 'center',
  marginRight: '-18px',
};

export default IntelligenceDialogWindow;
