import React from 'react';
import { Grid, Checkbox, FormControlLabel, Typography, TextField, Button } from '@mui/material';

function UpfitsForm({ upfits, setUpfits }) {

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setUpfits((prevUpfits) => ({
      ...prevUpfits,
      [name]: checked,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpfits((prevUpfits) => ({
      ...prevUpfits,
      [name]: value,
    }));
  };

  const populateRandomValues = () => {
    setUpfits({
      tires_mounted: 'all-season',
      tires_additional: 'winter',
      bluetooth_car_kit: Math.random() > 0.5,
      navigation_system: Math.random() > 0.5,
      tow_bar: Math.random() > 0.5,
      roof_rack: Math.random() > 0.5,
      ski_box: Math.random() > 0.5,
      snow_chains: Math.random() > 0.5,
    });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Vehicle Upfits / Modifications
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Tires Mounted"
            name="tires_mounted"
            value={upfits.tires_mounted || ''}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Additional Tires"
            name="tires_additional"
            value={upfits.tires_additional || ''}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.bluetooth_car_kit || false}
                onChange={handleCheckboxChange}
                name="bluetooth_car_kit"
                color="primary"
              />
            }
            label="Bluetooth Car Kit"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.navigation_system || false}
                onChange={handleCheckboxChange}
                name="navigation_system"
                color="primary"
              />
            }
            label="Navigation System"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.tow_bar || false}
                onChange={handleCheckboxChange}
                name="tow_bar"
                color="primary"
              />
            }
            label="Tow Bar"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.roof_rack || false}
                onChange={handleCheckboxChange}
                name="roof_rack"
                color="primary"
              />
            }
            label="Roof Rack"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.ski_box || false}
                onChange={handleCheckboxChange}
                name="ski_box"
                color="primary"
              />
            }
            label="Ski Box"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.snow_chains || false}
                onChange={handleCheckboxChange}
                name="snow_chains"
                color="primary"
              />
            }
            label="Snow Chains"
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={populateRandomValues}>
            Populate Random Values
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default UpfitsForm;
