import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, Tab, Box, Button, Typography } from '@mui/material';
import { LightbulbOutlined, SettingsSuggest } from '@mui/icons-material';

const TopNavigation = ({ isShowingDataView = false }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = React.useState(!isShowingDataView ? 2 : 5); // Set default tab

  const handleTabChange = (event, newValue) => {
    if (newValue !== 5) {
      navigate(`/tenant-processes/${id}/intelligence?embedded=true`);
    }
    setSelectedTab(newValue);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={"0 8px 0 0"}
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.15)"
      bgcolor="#fafafa"
    >
      {/* Tabs on the left */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: '#00965a', // Green underline for selected tab
            height: '4px',
          },
        }}
        textColor="inherit"
        sx={{
          '& .MuiTab-root': {
            minWidth: 'auto', // Reduce tab padding
            padding: '0 28px',
            textTransform: 'none',
            fontWeight: 500,
            color: '#00000099', // Default tab text color
            height: '62px',
            borderRight: '1px solid #0000001f', // Right border for each tab
            '&.Mui-selected': {
              color: '#000000de', // Selected tab text color
              backgroundColor: '#6cc04a1a', // Light green background when selected
              borderRadius: '0px 0px 0 0', // Rounded corners at the top
            },
          },
        }}
      >

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            minWidth: 'auto',
            padding: '0 32px',
            textTransform: 'none',
            fontWeight: 500,
            color: '#00000099',
            borderRight: '1px solid #0000001f',
          }}
        >
          <Typography variant='subtitle' style={{
            color: '#000',
            textAlign: 'center',
            fontFeatureSettings: 'liga off clig off',
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '175%',
            letterSpacing: '0.15px',
          }}>
            Intelligence
          </Typography>
        </Box>


        <Tab label=
          {<Typography variant='subtitle' style={{
            color: '#000',
            textAlign: 'center',
            fontFeatureSettings: 'liga off clig off',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '133.333%',
            letterSpacing: '0.4px',
          }}>
            Business KPI
          </Typography>}
        />
        <Tab label=
          {<Typography variant='subtitle' style={{
            color: '#000',
            textAlign: 'center',
            fontFeatureSettings: 'liga off clig off',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '133.333%',
            letterSpacing: '0.4px',
          }}>
            Stock Visibility
          </Typography>}
        />
        <Tab label=
          {<Typography variant='subtitle' style={{
            color: '#000',
            textAlign: 'center',
            fontFeatureSettings: 'liga off clig off',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '133.333%',
            letterSpacing: '0.4px',
          }}>
            Stock Management
          </Typography>}
        />
        <Tab label=
          {<Typography variant='subtitle' style={{
            color: '#000',
            textAlign: 'center',
            fontFeatureSettings: 'liga off clig off',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '133.333%',
            letterSpacing: '0.4px',
          }}>
            Stock Optimization
          </Typography>}
        />
        {isShowingDataView && <Tab label=
          {<Typography variant='subtitle' style={{
            color: '#000',
            textAlign: 'center',
            fontFeatureSettings: 'liga off clig off',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '133.333%',
            letterSpacing: '0.4px',
          }}>
            Data
          </Typography>}
        />}
      </Tabs>

      {/* Buttons on the right */}
      <Box>
        <Button
          variant="outlined"
          startIcon={<LightbulbOutlined />}
          sx={{
            marginRight: '16px',
            textTransform: 'none',
            fontWeight: 500,
            color: '#00965a',
            borderColor: '#ffffff',
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            fontFeatureSettings: 'liga off clig off',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: '160%',
            letterSpacing: '0.15px',
          }}
        >
          Key Insights
        </Button>
        <Button
          variant="outlined"
          color="success"
          startIcon={<SettingsSuggest />}
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            color: '#00965a',
            borderColor: '#ffffff',
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            fontFeatureSettings: 'liga off clig off',
            fontFamily: 'Roboto',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: '160%',
            letterSpacing: '0.15px',
          }}
        >
          Business Rules
        </Button>
      </Box>
    </Box>
  );
};

export default TopNavigation;
