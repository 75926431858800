import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material';
import { useLocation, Link as RouterLink } from 'react-router-dom';

function Breadcrumbs({ className }) {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className={className}>
      <MUIBreadcrumbs aria-label="breadcrumb" style={{ padding: '16px' }}>
        <Link component={RouterLink} to="/">
          Home
        </Link>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return last ? (
            <Typography color="textPrimary" key={to}>
              {value}
            </Typography>
          ) : (
            <Link component={RouterLink} to={to} key={to}>
              {value}
            </Link>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
}

export default Breadcrumbs;
