import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import axios from 'axios';

import IntelligenceVisualizer from '../../components/Intelligence/IntelligenceVisualizer';
import IntelligenceFilterAndActionsBar from '../../components/Intelligence/IntelligenceFilterAndActionsBar';
import IntelligenceFilterAndViewToggle from '../../components/Intelligence/IntelligenceFilterAndViewToggle';
import IntelligenceTopNavigation from '../../components/Intelligence/IntelligenceTopNavigation';

import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

const IntelligenceDashboardPage = () => {

  const { id, stage, season } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStage, setSelectedStage] = useState(null);



  useEffect(() => {
    fetchData();
  }, [stage, season, id]);


  const fetchData = async () => {
    const tokenDetails = localStorage.getItem('tokenDetails');
    try {
      const response = await axios.get('/mock/data/fleet', {
        headers: {
          Authorization: `JWT ${tokenDetails}`,
        },
      });
      const data = response.data;
      const activeSeason = data.seasons[season];
      const activeStage = activeSeason.stages.find((s) => s.name === stage);
      setSelectedStage(activeStage);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  return (
    <Grid id="intelligence-container" container direction="column" style={{
      height: '100vh',
      overflow: 'hidden',
      '@media (minWidth:600px)': {
        // paddingTop: '24px',
        paddingLeft: '4px',
        paddingRight: '4px',
      },
      '@media (minWidth:960px)': {
        // paddingTop: '32px',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      paddingLeft: '0px',
      paddingRight: '0px',
    }}>
      {/* Filter and View Toggle */}
      <Grid item style={{
        paddingBottom: '6px',
      }}>
        <IntelligenceTopNavigation isShowingDataView />
      </Grid>

      <Grid item style={{
        paddingBottom: '12px',
      }}>
        <IntelligenceFilterAndViewToggle />
      </Grid>

      {/* Dashboard */}
      <Grid item style={{ flexGrow: 1, overflowY: 'auto', marginTop: '-10px' }}>
        <LoadingIndicator isLoading={isLoading} />
        {selectedStage && (
          <iframe
            src={selectedStage.drilldown_url}
            title="Intelligence Visualizer"
            style={{
              width: '100%',
              height: '99%',
              border: 'none',    // Removes the border
              padding: 0,        // Removes any default padding
              margin: 0,          // Ensures there is no margin around the iframe
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default IntelligenceDashboardPage;
