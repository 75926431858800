import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container, Typography, TextField, Button, Checkbox,
  FormControlLabel, List, ListItem, CircularProgress,
  Snackbar, Alert, Divider
} from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';

const AssetOnboardingForm = () => {
  const { token } = useAuth();
  const { id: tenantId, xid } = useParams();
  const navigate = useNavigate();

  const [xidInput, setXidInput] = useState('');
  const [checklist, setChecklist] = useState([
    { id: 1, label: 'Order Accepted', completed: false, loading: false, status: 'ORDER_ACCEPTED', type: 'checkbox' },
    { id: 2, label: 'Scheduled For Production', completed: false, loading: false, status: 'SCHEDULED_FOR_PRODUCTION', type: 'checkbox' },
    { id: 3, label: 'Produced', completed: false, loading: false, status: 'PRODUCED', type: 'checkbox' },
    { id: 4, label: 'VIN Assigned To Vehicle', completed: false, loading: false, status: 'VIN_ASSIGNED_TO_VEHICLE', type: 'input', key: 'vin', value: '' },
  ]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });

  const handleCheckboxChange = async (id) => {
    const selectedItem = checklist.find((item) => item.id === id);
    const updatedChecklist = checklist.map((item) =>
      item.id === id ? { ...item, loading: true } : item
    );
    setChecklist(updatedChecklist);

    const payload = selectedItem.key
      ? { status: selectedItem.status, [selectedItem.key]: selectedItem.value }
      : { status: selectedItem.status };

    try {
      await axios.patch(
        `/api/v1/tenant/${tenantId}/assets/${xid}`,
        payload,
        { headers: { Authorization: `JWT ${token}` } }
      );

      setChecklist(
        checklist.map((item) =>
          item.id === id ? { ...item, completed: !item.completed, loading: false } : item
        )
      );
      setSnackbar({ open: true, message: 'Update successful', severity: 'success' });
    } catch (error) {
      console.error('Error making API call', error);
      setChecklist(
        checklist.map((item) => (item.id === id ? { ...item, loading: false } : item))
      );
      setSnackbar({ open: true, message: 'Update failed', severity: 'error' });
    }
  };

  const handleInputChange = (id, value) => {
    setChecklist((prev) =>
      prev.map((item) => (item.id === id ? { ...item, value } : item))
    );
  };

  const handleCloseSnackbar = () => setSnackbar({ open: false, message: '', severity: '' });

  const handleXidSubmit = (e) => {
    e.preventDefault();
    if (xidInput) navigate(`/tenant-processes/${tenantId}/vehicle-onboarding/${xidInput}`);
  };

  if (!xid) {
    return (
      <Container sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Enter Asset XID
        </Typography>
        <form onSubmit={handleXidSubmit} style={{ display: 'flex', gap: '16px' }}>
          <TextField
            label="XID"
            variant="outlined"
            value={xidInput}
            onChange={(e) => setXidInput(e.target.value)}
            required
          />
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </form>
      </Container>
    );
  }

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Asset Onboarding Checklist
      </Typography>
      <Divider sx={{ my: 2 }} />

      <List>
        {checklist.map((item) => (
          <ListItem key={item.id} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {item.type === 'checkbox' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.completed}
                    onChange={() => handleCheckboxChange(item.id)}
                    disabled={item.loading}
                  />
                }
                label={item.label}
              />
            ) : (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <TextField
                  label={item.label}
                  variant="outlined"
                  value={item.value || ''}
                  onChange={(e) => handleInputChange(item.id, e.target.value)}
                  disabled={item.completed || item.loading}
                />
                <Button
                  variant="contained"
                  onClick={() => handleCheckboxChange(item.id)}
                  disabled={item.loading}
                >
                  Submit
                </Button>
              </div>
            )}
            {item.loading && <CircularProgress size={24} />}
          </ListItem>
        ))}
      </List>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssetOnboardingForm;
