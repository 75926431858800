import createTheme from '@mui/material/styles/createTheme.js';


const theme = createTheme({
  palette: {
    primary: {
      main: '#00965a', // Green
    },
    secondary: {
      main: '#ffffff', // White
    },
    background: {
      default: '#ffffff', // White background
      paper: '#ffffff', // White background for paper components
    },
    text: {
      primary: '#000000', // Black text
      secondary: '#00965a', // Green text for secondary use
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h6: {
      color: '#000000', // Black text for headers
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          backgroundColor: 'transparent',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          margin: 0,
          textAlign: 'left',
          width: '100%',
        },
        '#root': {
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#00965a', // Green background for AppBar
          color: '#ffffff', // White text for AppBar
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff', // White background for Drawer
          color: '#000000', // Black text for Drawer
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#00965a', // Green background on hover
            color: '#ffffff', // White text on hover
          },
        },
      },
    },
  },
});

export default theme;
