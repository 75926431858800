import React, { useState } from 'react';
import Axios from 'axios';
import {
    Button, TextField, Typography, Container, Box, CssBaseline, IconButton,
    InputAdornment, Card, CardContent, CircularProgress, Snackbar, Alert, Divider,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ThemeProvider } from '@mui/material/styles';

import { useAuth } from '../../contexts/AuthContext/AuthContext.jsx';
import theme from '../../styles/theme.js';
import SuitBadgeLogo from './../../assets/suit_badge_2.png';
import RidecellLogoDark from './../../assets/ridecell_logo_dark.png';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { login } = useAuth();

    const isDevelopment = process.env.NODE_ENV === 'development';
    const organizationId = isDevelopment ? 'global-dev' : 'global-prod';

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await Axios.post('/api/v1/authentication/authenticate', { email, password, tenantId: organizationId });
            login(response.data.token);
            setLoading(false);
        } catch (err) {
            setError('Failed to authenticate. Please check your credentials and try again.');
            setLoading(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container
                component="main"
                maxWidth={false}
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'linear-gradient(180deg, #A05EB5 1%, #00B5E2 80%, #6CC04A 100%)',
                    padding: 0,
                    margin: 0,
                }}
            >
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        width: '80%',
                        maxWidth: 900,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        borderRadius: 2,
                        boxShadow: 3,
                        overflow: 'hidden',
                    }}
                >
                    {/* Card Content Section */}
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                        }}
                    >
                        <img src={SuitBadgeLogo} alt="Ridecell Suit Badge" style={{ width: 300, height: 300 }} />
                        <Card sx={{ mb: 2, width: '90%' }}>
                            <CardContent>
                                <Typography variant="body1" component="p" textAlign={'center'}>
                                    Welcome to the Solutions Utility Interface Tool
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>

                    {/* Divider */}
                    <Divider orientation="vertical" flexItem />

                    {/* Login Form Section */}
                    <Box
                        sx={{
                            flex: 1,
                            padding: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={RidecellLogoDark} alt="Ridecell Logo" style={{ width: 128 }} />
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address/Username"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled={loading}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                disabled={loading}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                disabled={loading}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
                                {isDevelopment
                                    ? 'Please note: For development, use the https://global.us.master.web-app.ridecell.io/admin-center account.'
                                    : 'Please note: For production, use either your US or EU production admin center accounts, region selection is handled automatically.'}
                            </Typography>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Sign In'}
                            </Button>
                            <Typography align="center" sx={{ mt: 2 }}>
                                © 2024 Ridecell. All rights reserved.
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
