import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';

import IntelligenceVisualizer from '../../components/Intelligence/IntelligenceVisualizer';
import IntelligenceFilterAndActionsBar from '../../components/Intelligence/IntelligenceFilterAndActionsBar';
import IntelligenceFilterAndViewToggle from '../../components/Intelligence/IntelligenceFilterAndViewToggle';
import IntelligenceTopNavigation from '../../components/Intelligence/IntelligenceTopNavigation';

import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

const IntelligencePage = () => {

  const [fleetData, setFleetData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [intelligenceDataView, setIntelligenceDataView] = useState('Idle Time');
  const [fleetFilter, setFleetFilter] = useState('Flex');

  useEffect(() => {
    fetchData();
  }, [fleetFilter])


  const fetchData = async () => {
    const tokenDetails = localStorage.getItem('tokenDetails');
    try {
      const response = await axios.get('/mock/data/fleet', {
        params: {
          fleet_id: fleetFilter,
        },
        headers: {
          Authorization: `JWT ${tokenDetails}`,
        },
      });
      setFleetData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  const didChangeIntelligenceDataView = (view) => {
    setIntelligenceDataView(view);
  }

  const didChangeFleetFilter = (filter) => {
    setFleetFilter(filter);
    setIsLoading(true);
  }


  return (
    <Grid id="intelligence-container" container direction="column" style={{
      height: '100vh',
      overflow: 'hidden',
      '@media (minWidth:600px)': {
        // paddingTop: '24px',
        paddingLeft: '4px',
        paddingRight: '4px',
      },
      '@media (minWidth:960px)': {
        // paddingTop: '32px',
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      paddingLeft: '0px',
      paddingRight: '0px',
    }}>
      {/* Filter and View Toggle */}
      <Grid item style={{
        paddingBottom: '6px',
      }}>
        <IntelligenceTopNavigation />
      </Grid>

      <Grid item style={{
        paddingBottom: '12px',
      }}>
        <IntelligenceFilterAndViewToggle />
      </Grid>

      {/* Filter and Actions Bar */}
      <Grid item>
        <IntelligenceFilterAndActionsBar
          didChangeIntelligenceDataView={didChangeIntelligenceDataView}
          didChangeFleetFilter={didChangeFleetFilter}
        />
      </Grid>

      {/* Main Visualizer, taking up remaining space */}
      <Grid item style={{ flexGrow: 1, overflowY: 'auto' }}>
        <LoadingIndicator isLoading={isLoading} />
        {fleetData && <IntelligenceVisualizer fleetData={fleetData} intelligenceDataView={intelligenceDataView} fleetId={fleetData.query_parameters.fleet_id} />}
      </Grid>
    </Grid>
  );
};

export default IntelligencePage;
