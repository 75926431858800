import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from 'axios';
import {
  Container, Button, Typography, Divider, CircularProgress, Snackbar, Alert
} from '@mui/material';
import VehicleDetailsForm from './../../../components/VehicleDetailsForm/VehicleDetailsForm';
import SpecificationsForm from '../../../components/SpecificationsForm/SpecificationsForm';
import UpfitsForm from './../../../components/UpfitsForm/UpfitsForm';
import KeyDatesForm from './../../../components/KeyDatesForm/KeyDatesForm';
import AdditionalDetailsForm from './../../../components/AdditionalDetailsForm/AdditionalDetailsForm';
import ReviewAndSubmit from './../../../components/ReviewAndSubmitForm/ReviewAndSubmitForm';
import { useAuth } from '../../../contexts/AuthContext/AuthContext';

function VehicleOrderForm() {
  const { id: tenantId } = useParams();
  const { token } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  const initialVehicleDetails = {
    xid: '',
    vin: '',
    license_plate: { number: '', state: '', expiration_date: '' }
  };

  const initialOrderDetails = {
    xid: '',
    status: 'NEW',
    expected_delivery_date: '',
    net_price: 0,
    additional_details: {
      arrived_to_dealer_date: '',
      cd_fee_included_in_invoice: '',
      courtesy_delivery_fee_paid_date: '',
      eta_to_dealer: '',
      last_event_date: '',
      manufacturer_accepted_date: '',
      manufacturer_rejected_date: '',
      paperwork_received_date: '',
      requested_target_production_week: '',
      shipping_method: '',
      target_production_week: '',
      vehicle_scheduled_for_production_date: '',
      shipped_date: ''
    }
  };

  const initialKeyDates = {
    registration_date: '',
    registration_expiration_date: '',
    legal_inspection_date: '',
    legal_inspection_expiration_date: '',
    emissions_permit_date: '',
    emissions_permit_expiration_date: ''
  };

  const initialSpecifications = {
    model_id: 0,
    make: '',
    model: '',
    model_year: 0,
    trim: '',
    color: '',
    ownership_type: 'owned',
    pool: 'active',
    details: {
      base_towing_capacity: '',
      body_type: '',
      max_hp: '',
      max_torque: '',
      fuel_tank_capacity: ''
    }
  };

  const initialUpfits = {
    tires_mounted: '',
    tires_additional: '',
    bluetooth_car_kit: false,
    navigation_system: false,
    tow_bar: false,
    roof_rack: false,
    ski_box: false,
    snow_chains: false
  };

  const [vehicleDetails, setVehicleDetails] = useState(initialVehicleDetails);
  const [orderDetails, setOrderDetails] = useState(initialOrderDetails);
  const [keyDates, setKeyDates] = useState(initialKeyDates);
  const [specifications, setSpecifications] = useState(initialSpecifications);
  const [upfits, setUpfits] = useState(initialUpfits);

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [successData, setSuccessData] = useState(null); // Track success response data

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      xid: vehicleDetails.xid,
      order: { ...orderDetails },
      dates: keyDates,
      specifications: { ...specifications, accessories: upfits },
      license_plate: vehicleDetails.license_plate
    };

    try {
      const response = await axios.post(`/api/v1/tenant/${tenantId}/assets`, payload, {
        headers: { Authorization: `JWT ${token}` }
      });

      if (response.data.error) {
        setSnackbar({ open: true, message: response.data.error.user_message, severity: 'error' });
      } else {
        setSnackbar({
          open: true,
          message: `Submission successful! XID: ${response.data.xid}`,
          severity: 'success'
        });

        setSuccessData(response.data); // Store success response

        // Reset all forms to initial state
        setVehicleDetails(initialVehicleDetails);
        setOrderDetails(initialOrderDetails);
        setKeyDates(initialKeyDates);
        setSpecifications(initialSpecifications);
        setUpfits(initialUpfits);
      }
    } catch (error) {
      console.error('API error:', error);
      setSnackbar({ open: true, message: 'Submission failed. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleOnboardRedirect = () => {
    navigate(`/tenant-processes/${tenantId}/vehicle-onboarding/${successData.xid}`);
  };

  return (
    <Container sx={{ marginBottom: 4 }}>
      <Typography variant="h4" paragraph>
        Vehicle Order Form
      </Typography>

      <Divider style={{ marginBottom: '24px' }} />

      <VehicleDetailsForm
        vehicleDetails={vehicleDetails}
        setVehicleDetails={setVehicleDetails}
      />

      <Divider style={{ margin: '24px 0' }} />

      <SpecificationsForm
        specifications={specifications}
        setSpecifications={setSpecifications}
      />

      <Divider style={{ margin: '24px 0' }} />

      <UpfitsForm upfits={upfits} setUpfits={setUpfits} />

      <Divider style={{ margin: '24px 0' }} />

      <AdditionalDetailsForm
        orderDetails={orderDetails}
        setOrderDetails={setOrderDetails}
      />

      <Divider style={{ margin: '24px 0' }} />

      <KeyDatesForm keyDates={keyDates} setKeyDates={setKeyDates} />

      <Divider style={{ margin: '24px 0' }} />

      <ReviewAndSubmit
        handleSubmit={handleSubmit}
        vehicleDetails={vehicleDetails}
        specifications={specifications}
        upfits={upfits}
        orderDetails={orderDetails}
        keyDates={keyDates}
      />

      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
          <CircularProgress />
        </div>
      )}

      {successData && (
        <div style={{ marginTop: 16, textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={handleOnboardRedirect}>
            Proceed to Vehicle Onboarding
          </Button>
        </div>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default VehicleOrderForm;
