import React from 'react';
import { Grid, TextField, Button, Typography } from '@mui/material';

function KeyDatesForm({ keyDates, setKeyDates }) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setKeyDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };

  const populateRandomDates = () => {
    const randomDate = () => new Date(Date.now() - Math.floor(Math.random() * 1e10))
      .toISOString().slice(0, 10); // Format as YYYY-MM-DD

    setKeyDates({
      registration_date: randomDate(),
      registration_expiration_date: randomDate(),
      legal_inspection_date: randomDate(),
      legal_inspection_expiration_date: randomDate(),
      emissions_permit_date: randomDate(),
      emissions_permit_expiration_date: randomDate(),
    });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Key Dates
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="date"
            label="Registration Date"
            name="registration_date"
            value={keyDates.registration_date || ''}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="date"
            label="Registration Expiration Date"
            name="registration_expiration_date"
            value={keyDates.registration_expiration_date || ''}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="date"
            label="Legal Inspection Date"
            name="legal_inspection_date"
            value={keyDates.legal_inspection_date || ''}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="date"
            label="Legal Inspection Expiration Date"
            name="legal_inspection_expiration_date"
            value={keyDates.legal_inspection_expiration_date || ''}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="date"
            label="Emissions Permit Date"
            name="emissions_permit_date"
            value={keyDates.emissions_permit_date || ''}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            type="date"
            label="Emissions Permit Expiration Date"
            name="emissions_permit_expiration_date"
            value={keyDates.emissions_permit_expiration_date || ''}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={populateRandomDates}>
            Populate Random Dates
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default KeyDatesForm;
