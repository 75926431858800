import React from 'react';
import { Grid, TextField, MenuItem, Button } from '@mui/material';

const licensePlateStates = ['CA', 'TX', 'NY', 'FL', 'TN']; // Example states, stubbed out
const generateRandomVIN = () => `VIN-${Math.floor(100000 + Math.random() * 900000)}`; // Simple VIN generator

function VehicleDetailsForm({ vehicleDetails, setVehicleDetails }) {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const populateRandomValues = () => {
    setVehicleDetails({
      xid: `XID-${Math.floor(1000 + Math.random() * 9000)}`,
      vin: generateRandomVIN(),
      license_plate: {
        number: `${Math.floor(100 + Math.random() * 900)}-ABC`,
        state: licensePlateStates[Math.floor(Math.random() * licensePlateStates.length)],
        expiration_date: new Date().toISOString().split('T')[0], // Format as YYYY-MM-DD
      },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="XID"
          name="xid"
          value={vehicleDetails.xid || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="VIN"
          name="vin"
          value={vehicleDetails.vin || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="License Plate Number"
          name="license_plate.number"
          value={vehicleDetails.license_plate?.number || ''}
          onChange={(e) =>
            setVehicleDetails((prevDetails) => ({
              ...prevDetails,
              license_plate: {
                ...prevDetails.license_plate,
                number: e.target.value,
              },
            }))
          }
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="License Plate State"
          name="license_plate.state"
          value={vehicleDetails.license_plate?.state || ''}
          onChange={(e) =>
            setVehicleDetails((prevDetails) => ({
              ...prevDetails,
              license_plate: {
                ...prevDetails.license_plate,
                state: e.target.value,
              },
            }))
          }
          variant="outlined"
        >
          {licensePlateStates.map((state) => (
            <MenuItem key={state} value={state}>
              {state}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          type="date"
          label="License Plate Expiration Date"
          name="license_plate.expiration_date"
          value={vehicleDetails.license_plate?.expiration_date || ''}
          onChange={(e) =>
            setVehicleDetails((prevDetails) => ({
              ...prevDetails,
              license_plate: {
                ...prevDetails.license_plate,
                expiration_date: e.target.value,
              },
            }))
          }
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={populateRandomValues}>
          Populate Random Values
        </Button>
      </Grid>
    </Grid>
  );
}

export default VehicleDetailsForm;
