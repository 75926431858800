import React from 'react';
import { getBezierPath, getStraightPath } from 'reactflow';

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data = {},
}) => {
  const {
    angle,
    isAtEnd = false,
    isStraightLine = false,
    xOffset = 0,
    yOffset = 0,
    curvature = 0.5, // Default curvature value
  } = data;

  const [edgePath] = !isStraightLine
    ? getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      curvature, // Use the curvature value from data or default
    })
    : getStraightPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });

  // Manually calculate the midpoint of the edge
  const midX = !isAtEnd ? (sourceX + targetX) / 2 : targetX + xOffset;
  const midY = !isAtEnd ? (sourceY + targetY) / 2 : targetY + yOffset;

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <polygon
        points="-7,0 7,0 0,-7" // This makes the arrow bigger
        fill="#888"
        transform={`translate(${midX}, ${midY}) rotate(${angle || 0})`}
      />
    </>
  );
};

export default CustomEdge;
