import React, { useState } from 'react';
import { Grid, TextField, MenuItem, Button } from '@mui/material';

const makes = ['Ford', 'Chevrolet', 'Toyota']; // Stubbed makes
const models = {
  Ford: ['F-150', 'Mustang', 'Explorer'],
  Chevrolet: ['Silverado', 'Camaro', 'Equinox'],
  Toyota: ['Corolla', 'Camry', 'RAV4']
}; // Example models for each make
const trims = ['Base', 'Sport', 'Luxury']; // Stubbed trims
const colors = ['Blue', 'Black', 'White', 'Silver']; // Stubbed colors

function SpecificationsForm({ specifications, setSpecifications }) {
  const [selectedMake, setSelectedMake] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpecifications((prevSpecs) => ({
      ...prevSpecs,
      [name]: value,
    }));
  };

  const handleMakeChange = (e) => {
    const newMake = e.target.value;
    setSelectedMake(newMake);

    // Reset model when make changes
    setSpecifications((prevSpecs) => ({
      ...prevSpecs,
      make: newMake,
      model: models[newMake][0] || '', // Set to the first model for the selected make
    }));
  };

  const populateRandomValues = () => {
    const getRandomElement = (arr) => arr[Math.floor(Math.random() * arr.length)];

    const randomMake = getRandomElement(makes);
    const randomModel = getRandomElement(models[randomMake]);

    setSelectedMake(randomMake); // Update selectedMake state

    setSpecifications({
      model_id: Math.floor(Math.random() * 1000),
      make: randomMake,
      model: randomModel,
      model_year: 2000 + Math.floor(Math.random() * 25),
      trim: getRandomElement(trims),
      color: getRandomElement(colors),
      ownership_type: 'owned',
      pool: 'active',
      details: {
        base_towing_capacity: `${Math.floor(Math.random() * 5000)} lbs`,
        body_type: 'SUV',
        max_hp: `${Math.floor(Math.random() * 400)} HP`,
        max_torque: `${Math.floor(Math.random() * 600)} Nm`,
        fuel_tank_capacity: `${Math.floor(Math.random() * 30) + 10} gal`
      }
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Make"
          name="make"
          select
          value={specifications.make || ''}
          onChange={handleMakeChange}
          variant="outlined"
        >
          {makes.map((make) => (
            <MenuItem key={make} value={make}>
              {make}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Model"
          name="model"
          select
          value={specifications.model || ''}
          onChange={handleInputChange}
          variant="outlined"
        >
          {(models[selectedMake] || []).map((model) => (
            <MenuItem key={model} value={model}>
              {model}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          type="number"
          label="Model Year"
          name="model_year"
          value={specifications.model_year || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Trim"
          name="trim"
          select
          value={specifications.trim || ''}
          onChange={handleInputChange}
          variant="outlined"
        >
          {trims.map((trim) => (
            <MenuItem key={trim} value={trim}>
              {trim}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Color"
          name="color"
          select
          value={specifications.color || ''}
          onChange={handleInputChange}
          variant="outlined"
        >
          {colors.map((color) => (
            <MenuItem key={color} value={color}>
              {color}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={populateRandomValues}>
          Populate Random Values
        </Button>
      </Grid>
    </Grid>
  );
}

export default SpecificationsForm;
